// src/hooks/useActivityTracker.js
import { useState, useEffect } from 'react';

const useActivityTracker = (timeout = 300000) => { // 5 minutes default timeout
  const [isActive, setIsActive] = useState(true);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [countdown, setCountdown] = useState(30);
  const [isAutoLogout, setIsAutoLogout] = useState(false);

  useEffect(() => {
    let activityTimeout;
    let countdownInterval;

    const resetTimeout = () => {
      clearTimeout(activityTimeout);
      clearInterval(countdownInterval);
      setIsActive(true);
      setShowLogoutModal(false);
      setIsAutoLogout(false);
      setCountdown(30);

      activityTimeout = setTimeout(() => {
        setIsAutoLogout(true);
        setShowLogoutModal(true);
        countdownInterval = setInterval(() => {
          setCountdown((prev) => {
            if (prev === 1) {
              clearInterval(countdownInterval);
              handleLogout();
            }
            return prev - 1;
          });
        }, 1000);
      }, timeout);
    };

    const handleActivity = () => {
      setIsActive(true);
      resetTimeout();
    };

    const handleLogout = () => {
      clearTimeout(activityTimeout);
      clearInterval(countdownInterval);
      // Add your logout logic here, e.g., call logout function or navigate to login page
    };

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keypress', handleActivity);

    resetTimeout();

    return () => {
      clearTimeout(activityTimeout);
      clearInterval(countdownInterval);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keypress', handleActivity);
    };
  }, [timeout]);

  return { isActive, showLogoutModal, countdown, setShowLogoutModal, isAutoLogout };
};

export default useActivityTracker;
