// src/components/Modals/ReplyModal.js
import React from 'react';
import { FaTimes } from 'react-icons/fa';

const ReplyModal = ({ replyData, setReplyData, onClose, onSubmit }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setReplyData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleReply = () => {
    onSubmit();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center backdrop-filter backdrop-blur-sm transition-opacity duration-300 ease-in-out">
      <div className="bg-white p-6 rounded-lg shadow-xl w-96 transform transition-transform duration-300 ease-in-out scale-100">
        <div className="flex justify-between items-center mb-4 border-b pb-2">
          <h2 className="text-2xl font-semibold text-gray-800">Reply to Message</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-red-500 transition duration-200">
            <FaTimes size={20} />
          </button>
        </div>
        <div className="mb-4">
          <strong>Replying to User ID:</strong> {replyData.user_id}
        </div>
        <textarea
          name="reply_message"
          placeholder="Reply Message"
          value={replyData.reply_message}
          onChange={handleInputChange}
          className="p-2 border rounded mb-2 w-full"
        ></textarea>
        <button
          className="bg-green-500 text-white px-4 py-2 rounded shadow-lg hover:bg-green-600 transition duration-200"
          onClick={handleReply}
        >
          Send Reply
        </button>
      </div>
    </div>
  );
};

export default ReplyModal;
