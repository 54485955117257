import React, { useState, useEffect } from 'react';
import { FaPlus, FaEdit, FaTrash } from 'react-icons/fa';
import {
  fetchAnnouncements,
  createAnnouncement,
  updateAnnouncement,
  deleteAnnouncement,
} from '../components/services/api';
import Modal from '../components/Modals/Modal';

const Announcements = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [editMessage, setEditMessage] = useState('');
  const [editId, setEditId] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    fetchAnnouncementsData();
  }, []);

  const fetchAnnouncementsData = async () => {
    setLoading(true);
    try {
      const response = await fetchAnnouncements();
      if (Array.isArray(response)) {
        setAnnouncements(response);
      } else {
        console.error('Error: Response is not an array');
        setAnnouncements([]);
      }
    } catch (error) {
      console.error('Error fetching announcements', error);
      setAnnouncements([]);
    }
    setLoading(false);
  };

  const handleCreateAnnouncement = async () => {
    setLoading(true);
    try {
      if (newMessage.trim()) {
        const response = await createAnnouncement(newMessage);
        if (response.success) {
          fetchAnnouncementsData();
          setNewMessage('');
          setShowForm(false);
          setMessage('Announcement created successfully');
        } else {
          alert('Error creating announcement');
        }
      } else {
        alert('Announcement message cannot be empty');
      }
    } catch (error) {
      console.error('Error creating announcement', error);
    }
    setLoading(false);
  };

  const handleUpdateAnnouncement = async () => {
    setLoading(true);
    try {
      if (editMessage.trim()) {
        const response = await updateAnnouncement(editId, editMessage);
        if (response.success) {
          fetchAnnouncementsData();
          setEditMessage('');
          setEditId(null);
          setShowForm(false);
          setMessage('Announcement updated successfully');
        } else {
          alert('Error updating announcement');
        }
      } else {
        alert('Announcement message cannot be empty');
      }
    } catch (error) {
      console.error('Error updating announcement', error);
    }
    setLoading(false);
  };

  const handleDeleteAnnouncement = async (id) => {
    setLoading(true);
    try {
      const response = await deleteAnnouncement(id);
      if (response.success) {
        fetchAnnouncementsData();
        setMessage('Announcement deleted successfully');
      } else {
        alert('Error deleting announcement');
      }
    } catch (error) {
      console.error('Error deleting announcement', error);
    }
    setLoading(false);
  };

  const closeModal = () => {
    setShowForm(false);
    setEditId(null);
    setNewMessage('');
    setEditMessage('');
  };

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold text-gray-800">Announcements</h1>
        <button
          className="bg-blue-600 text-white px-4 py-2 rounded flex items-center shadow-lg hover:bg-blue-700 transition duration-200"
          onClick={() => setShowForm(true)}
        >
          <FaPlus className="mr-2" />
          Create Announcement
        </button>
      </div>

      {message && (
        <div className="mb-4 p-4 bg-green-200 text-green-800 rounded shadow-md">
          {message}
        </div>
      )}

      {showForm && (
        <Modal title={editId ? 'Edit Announcement' : 'Create Announcement'} onClose={closeModal}>
          <textarea
            className="w-full p-2 border rounded mb-2"
            placeholder={editId ? 'Edit announcement' : 'Enter new announcement'}
            value={editId ? editMessage : newMessage}
            onChange={(e) => editId ? setEditMessage(e.target.value) : setNewMessage(e.target.value)}
          ></textarea>
          <button
            className="bg-blue-600 text-white px-4 py-2 rounded shadow-lg hover:bg-blue-700 transition duration-200"
            onClick={editId ? handleUpdateAnnouncement : handleCreateAnnouncement}
          >
            {editId ? 'Update Announcement' : 'Create Announcement'}
          </button>
        </Modal>
      )}

      {loading ? (
        <div className="text-center py-6">Loading...</div>
      ) : (
        <div className="bg-white shadow-md rounded overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead className="bg-gray-200">
              <tr>
                <th className="py-3 px-4 border-b-2">Message</th>
                <th className="py-3 px-4 border-b-2">Created Date</th>
                <th className="py-3 px-4 border-b-2">Action</th>
              </tr>
            </thead>
            <tbody>
              {announcements.length > 0 ? (
                announcements.map((announcement) => (
                  <tr key={announcement.id} className="hover:bg-gray-100 transition duration-200">
                    <td className="py-3 px-4 border-b">{announcement.message}</td>
                    <td className="py-3 px-4 border-b">
                      {new Date(announcement.created_at).toLocaleString()}
                    </td>
                    <td className="py-3 px-4 border-b flex space-x-2">
                      <button
                        className="bg-yellow-500 text-white px-3 py-2 rounded shadow-lg hover:bg-yellow-600 transition duration-200"
                        onClick={() => {
                          setEditMessage(announcement.message);
                          setEditId(announcement.id);
                          setShowForm(true);
                        }}
                      >
                        <FaEdit />
                      </button>
                      <button
                        className="bg-red-500 text-white px-3 py-2 rounded shadow-lg hover:bg-red-600 transition duration-200"
                        onClick={() => handleDeleteAnnouncement(announcement.id)}
                      >
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3" className="py-3 px-4 border-b text-center">No announcements available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}

      {editId && !showForm && (
        <div className="mt-6 bg-white p-4 rounded shadow-md">
          <textarea
            className="w-full p-2 border rounded"
            placeholder="Edit announcement"
            value={editMessage}
            onChange={(e) => setEditMessage(e.target.value)}
          ></textarea>
          <button
            className="bg-green-600 text-white px-4 py-2 rounded mt-2 shadow-lg hover:bg-green-700 transition duration-200"
            onClick={handleUpdateAnnouncement}
          >
            Update Announcement
          </button>
        </div>
      )}
    </div>
  );
};

export default Announcements;
