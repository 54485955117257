import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetch2FAStatus, enable2FA, disable2FA } from '../components/services/authApi';
import { useAuth } from '../context/AuthContext';
import AuthModal from '../components/Modals/AuthModal';
import ChangePinModal from '../components/Modals/ChangePinModal';

const Settings = () => {
  const { is2FAEnabled, setIs2FAEnabled } = useAuth();
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const [twoFaCode, setTwoFaCode] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [generatedCode, setGeneratedCode] = useState('');
  const [isChangePinModalOpen, setIsChangePinModalOpen] = useState(false);
  const [fetchedStatus, setFetchedStatus] = useState(false);

  useEffect(() => {
    const get2FAStatus = async () => {
      try {
        const data = await fetch2FAStatus();
        setIs2FAEnabled(data.is2FAEnabled);
        setQrCodeUrl(data.qrCodeUrl || '');
        if (!fetchedStatus) {
          if (data.is2FAEnabled) {
            toast.info('2FA is currently enabled.');
          } else {
            toast.info('2FA is currently disabled.');
          }
          setFetchedStatus(true);
        }
      } catch (error) {
        console.error('Error fetching 2FA status:', error);
      }
    };

    get2FAStatus();

    return () => {
      toast.dismiss();
    };
  }, [setIs2FAEnabled, fetchedStatus]);

  const handle2FAEnable = async () => {
    try {
      const data = await enable2FA();
      if (data.success) {
        setIs2FAEnabled(true);
        setGeneratedCode(data.generatedCode);
        toast.success(data.message);
        setIsModalOpen(true); // Open modal to show the generated code
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error('Error enabling 2FA:', error);
      toast.error('Failed to enable 2FA.');
    }
  };

  const handle2FADisable = async () => {
    try {
      const data = await disable2FA(twoFaCode);
      if (data.success) {
        setIs2FAEnabled(false);
        setQrCodeUrl('');
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error('Error disabling 2FA:', error);
      toast.error('Failed to disable 2FA.');
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setGeneratedCode('');
  };

  const handleChangePinClick = () => {
    setIsChangePinModalOpen(true);
  };

  const closeChangePinModal = () => {
    setIsChangePinModalOpen(false);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <h2 className="text-2xl mb-6 text-center">Settings</h2>
        <div className="grid grid-cols-1 gap-4">
          <div className={`p-4 border rounded-lg ${is2FAEnabled ? 'bg-green-100' : 'bg-red-100'}`}>
            <h3 className="text-xl font-bold mb-4">
              {is2FAEnabled ? '2FA is Enabled' : '2FA is Disabled'}
            </h3>
            {is2FAEnabled ? (
              <div>
                <p className="mb-4">Your account is protected with 2FA.</p>
                <input
                  type="text"
                  value={twoFaCode}
                  onChange={(e) => setTwoFaCode(e.target.value)}
                  placeholder="Enter 2FA code to disable"
                  className="mb-4 p-2 border rounded w-full"
                />
                <button
                  onClick={handle2FADisable}
                  className="py-2 px-4 rounded bg-red-500 hover:bg-red-700 text-white font-bold"
                >
                  Disable 2FA
                </button>
              </div>
            ) : (
              <div>
                <p className="mb-4">Your account is not protected with 2FA.</p>
                <button
                  onClick={handle2FAEnable}
                  className="py-2 px-4 rounded bg-green-500 hover:bg-green-700 text-white font-bold"
                >
                  Enable 2FA
                </button>
              </div>
            )}
          </div>
          <div className="p-4 border rounded-lg">
            <h3 className="text-xl font-bold mb-4">Change PIN</h3>
            <button
              onClick={handleChangePinClick}
              className="py-2 px-4 rounded bg-blue-500 hover:bg-blue-700 text-white font-bold"
            >
              Change PIN
            </button>
          </div>
        </div>
        <ToastContainer />
      </div>

      {/* Modal for displaying the generated 2FA code */}
      <AuthModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        title="Your 2FA Code"
        message="This is your 2FA code. Please copy and save it securely as you will not be able to view it again."
        code={generatedCode}
      />

      {/* Modal for changing the PIN */}
      <ChangePinModal
        isOpen={isChangePinModalOpen}
        onRequestClose={closeChangePinModal}
      />
    </div>
  );
};

export default Settings;
