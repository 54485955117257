import React, { useState } from 'react';
import { Bar, Line, Doughnut, PolarArea } from 'react-chartjs-2';
import { FaBell } from 'react-icons/fa';
import {
  barChartData, barChartOptions,
  lineChartData, lineChartOptions,
  doughnutChartData, doughnutChartOptions,
  polarAreaChartData, polarAreaChartOptions
} from '../chartConfig';
import './RealTimeNotificationsGroup.css';

const sections = [
  { id: 'critical-issue-alerts', label: 'Critical Issue Alerts', icon: FaBell },
  { id: 'warning-notifications', label: 'Warning Notifications', icon: FaBell },
  { id: 'success-notifications', label: 'Success Notifications', icon: FaBell },
  { id: 'alert-types-distribution', label: 'Alert Types Distribution', icon: FaBell }
];

const RealTimeNotificationsGroup = () => {
  const [activeSection, setActiveSection] = useState(sections[0].id);

  const renderContent = () => {
    switch (activeSection) {
      case 'critical-issue-alerts':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center">
              <FaBell className="mr-2" /> Critical Issue Alerts
            </h2>
            <Bar data={barChartData} options={barChartOptions} />
          </div>
        );
      case 'warning-notifications':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center">
              <FaBell className="mr-2" /> Warning Notifications
            </h2>
            <Line data={lineChartData} options={lineChartOptions} />
          </div>
        );
      case 'success-notifications':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center">
              <FaBell className="mr-2" /> Success Notifications
            </h2>
            <Doughnut data={doughnutChartData} options={doughnutChartOptions} />
          </div>
        );
      case 'alert-types-distribution':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center">
              <FaBell className="mr-2" /> Alert Types Distribution
            </h2>
            <PolarArea data={polarAreaChartData} options={polarAreaChartOptions} />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="real-time-notifications-group bg-white shadow-lg rounded-lg p-6">
      <h2 className="text-2xl font-bold flex items-center mb-4">
        <FaBell className="mr-2" /> Real-Time Notifications Dashboard
      </h2>
      <div className="navigation mb-4">
        {sections.map((section) => (
          <button
            key={section.id}
            className={`nav-button ${activeSection === section.id ? 'active' : ''}`}
            onClick={() => setActiveSection(section.id)}
          >
            <section.icon className="mr-2" />
            {section.label}
          </button>
        ))}
      </div>
      <div className="content">{renderContent()}</div>
    </div>
  );
};

export default RealTimeNotificationsGroup;
