import React, { useState, useEffect } from 'react';
import { fetchNews, createNews, updateNews, deleteNews } from '../components/services/api';
import { FaNewspaper, FaTrash, FaEdit, FaPlus } from 'react-icons/fa';
import Modal from '../components/Modals/Modal';

const News = () => {
  const [news, setNews] = useState([]);
  const [newTitle, setNewTitle] = useState('');
  const [newMessage, setNewMessage] = useState('');
  const [editTitle, setEditTitle] = useState('');
  const [editMessage, setEditMessage] = useState('');
  const [editId, setEditId] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    fetchNewsData();
  }, []);

  const fetchNewsData = async () => {
    setLoading(true);
    try {
      const response = await fetchNews();
      if (Array.isArray(response)) {
        setNews(response);
      } else {
        throw new Error('Response is not an array');
      }
    } catch (error) {
      console.error('Error fetching news', error);
    }
    setLoading(false);
  };

  const handleCreateNews = async () => {
    if (!newTitle.trim() || !newMessage.trim()) {
      alert('Title and message cannot be empty');
      return;
    }
    setLoading(true);
    try {
      const response = await createNews(newTitle, newMessage);
      if (response.success) {
        fetchNewsData();
        setNewTitle('');
        setNewMessage('');
        setShowCreateForm(false);
        setMessage('News created successfully');
      } else {
        alert('Error creating news: ' + response.error);
      }
    } catch (error) {
      console.error('Error creating news', error);
    }
    setLoading(false);
  };

  const handleUpdateNews = async () => {
    setLoading(true);
    try {
      const response = await updateNews(editId, editTitle, editMessage);
      if (response.success) {
        fetchNewsData();
        setEditTitle('');
        setEditMessage('');
        setEditId(null);
        setMessage('News updated successfully');
      } else {
        alert('Error updating news: ' + response.error);
      }
    } catch (error) {
      console.error('Error updating news', error);
    }
    setLoading(false);
  };

  const handleDeleteNews = async (id) => {
    setLoading(true);
    try {
      const response = await deleteNews(id);
      if (response.success) {
        fetchNewsData();
        setMessage('News deleted successfully');
      } else {
        console.error('Error deleting news:', response.error);
        alert('Error deleting news: ' + response.error);
      }
    } catch (error) {
      console.error('Error deleting news', error);
      alert('Error deleting news: ' + error.message);
    }
    setLoading(false);
  };
  

  const closeModal = () => {
    setShowCreateForm(false);
    setEditId(null);
    setNewTitle('');
    setNewMessage('');
    setEditTitle('');
    setEditMessage('');
  };

  return (
    <div className="min-h-screen bg-gray-100 p-4">
      <h1 className="text-2xl font-bold mb-4">News</h1>
      <button
        className="bg-blue-500 text-white px-4 py-2 rounded flex items-center shadow-lg hover:bg-blue-600 transition duration-200"
        onClick={() => setShowCreateForm(true)}
      >
        <FaPlus className="mr-2" /> Create News
      </button>
      {message && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4" role="alert">
          {message}
        </div>
      )}
      {loading ? (
        <div className="text-center py-6">Loading...</div>
      ) : (
        <table className="min-w-full bg-white rounded-lg shadow-lg">
          <thead>
            <tr className="bg-gray-200">
              <th className="py-2 px-4 border-b-2">Title</th>
              <th className="py-2 px-4 border-b-2">Message</th>
              <th className="py-2 px-4 border-b-2">Created Date</th>
              <th className="py-2 px-4 border-b-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {news.map((item) => (
              <tr key={item.id} className="hover:bg-gray-100 transition duration-200">
                <td className="border px-4 py-2">{item.title}</td>
                <td className="border px-4 py-2">{item.message}</td>
                <td className="border px-4 py-2">{new Date(item.timestamp).toLocaleString()}</td>
                <td className="border px-4 py-2 flex space-x-2">
                  <button
                    className="bg-yellow-500 text-white px-2 py-1 rounded shadow-lg hover:bg-yellow-600 transition duration-200"
                    onClick={() => {
                      setEditTitle(item.title);
                      setEditMessage(item.message);
                      setEditId(item.id);
                      setShowCreateForm(true);
                    }}
                  >
                    <FaEdit />
                  </button>
                  <button
                    className="bg-red-500 text-white px-2 py-1 rounded shadow-lg hover:bg-red-600 transition duration-200"
                    onClick={() => handleDeleteNews(item.id)}
                  >
                    <FaTrash />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {showCreateForm && (
        <Modal title={editId ? 'Edit News' : 'Create News'} onClose={closeModal}>
          <input
            type="text"
            className="w-full p-2 border rounded mb-2"
            placeholder="Enter news title"
            value={editId ? editTitle : newTitle}
            onChange={(e) => (editId ? setEditTitle(e.target.value) : setNewTitle(e.target.value))}
          />
          <textarea
            placeholder="Enter news message"
            className="w-full p-2 border rounded mb-2"
            value={editId ? editMessage : newMessage}
            onChange={(e) => (editId ? setEditMessage(e.target.value) : setNewMessage(e.target.value))}
          ></textarea>
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded shadow-lg hover:bg-blue-600 transition duration-200"
            onClick={editId ? handleUpdateNews : handleCreateNews}
          >
            {editId ? 'Update News' : 'Submit News'}
          </button>
        </Modal>
      )}
    </div>
  );
};

export default News;
