// src/components/Modals/ServerDetailsModal.js
import React, { useState, useEffect } from 'react';
import { FaTimes, FaArrowLeft } from 'react-icons/fa';
import { fetchServerInfo } from '../services/ServerApi';
import './ServerDetailsModal.css';

const ServerDetailsModal = ({ onClose, onBack }) => {
  const [serverInfo, setServerInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getServerInfo = async () => {
      try {
        const data = await fetchServerInfo();
        setServerInfo(data);
        setLoading(false); // Set loading to false after fetching data
      } catch (error) {
        console.error('Error fetching server information:', error);
        setLoading(false); // Set loading to false on error
      }
    };

    getServerInfo();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!serverInfo) {
    return <div>Error loading server information.</div>;
  }


  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <div className="modal-header">
          <button className="back-button" onClick={onBack}>
            <FaArrowLeft />
          </button>
          <h2>Server Details</h2>
          <button className="close-button" onClick={onClose}>
            <FaTimes />
          </button>
        </div>
        <div className="modal-content">
          <table className="server-info-table">
            <tbody>
              <tr>
                <th>Server Name</th>
                <td>{serverInfo.serverName}</td>
              </tr>
              <tr>
                <th>PHP Version</th>
                <td>{serverInfo.phpVersion}</td>
              </tr>
              <tr>
                <th>Document Root</th>
                <td>{serverInfo.documentRoot}</td>
              </tr>
              <tr>
                <th>Server Software</th>
                <td>{serverInfo.serverSoftware}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ServerDetailsModal;
