import React, { useState } from 'react';
import { Bar, Line, Doughnut, PolarArea } from 'react-chartjs-2';
import { FaTools } from 'react-icons/fa';
import {
  barChartData, barChartOptions,
  lineChartData, lineChartOptions,
  doughnutChartData, doughnutChartOptions,
  polarAreaChartData, polarAreaChartOptions
} from '../chartConfig';
import './DeploymentMetricsGroup.css';

const sections = [
  { id: 'build-times', label: 'Build Times', icon: FaTools },
  { id: 'deployment-success-rates', label: 'Deployment Success Rates', icon: FaTools },
  { id: 'rollback-events', label: 'Rollback Events', icon: FaTools },
  { id: 'deployment-failures', label: 'Deployment Failures', icon: FaTools }
];

const DeploymentMetricsGroup = () => {
  const [activeSection, setActiveSection] = useState(sections[0].id);

  const renderContent = () => {
    switch (activeSection) {
      case 'build-times':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center">
              <FaTools className="mr-2" /> Build Times
            </h2>
            <Line data={lineChartData} options={lineChartOptions} />
          </div>
        );
      case 'deployment-success-rates':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center">
              <FaTools className="mr-2" /> Deployment Success Rates
            </h2>
            <Bar data={barChartData} options={barChartOptions} />
          </div>
        );
      case 'rollback-events':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center">
              <FaTools className="mr-2" /> Rollback Events
            </h2>
            <Doughnut data={doughnutChartData} options={doughnutChartOptions} />
          </div>
        );
      case 'deployment-failures':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center">
              <FaTools className="mr-2" /> Deployment Failures
            </h2>
            <PolarArea data={polarAreaChartData} options={polarAreaChartOptions} />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="deployment-metrics-group bg-white shadow-lg rounded-lg p-6">
      <h2 className="text-2xl font-bold flex items-center mb-4">
        Deployment Metrics Dashboard
      </h2>
      <div className="navigation mb-4">
        {sections.map((section) => (
          <button
            key={section.id}
            className={`nav-button ${activeSection === section.id ? 'active' : ''}`}
            onClick={() => setActiveSection(section.id)}
          >
            <section.icon className="mr-2" />
            {section.label}
          </button>
        ))}
      </div>
      <div className="content">{renderContent()}</div>
    </div>
  );
};

export default DeploymentMetricsGroup;
