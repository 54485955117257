import React, { useEffect, useState } from 'react';
import { fetchUsers, deleteUser } from '../services/api';
import UserDetails from './UserDetails';
import ConfirmationModal from '../Modals/ConfirmationModal';
import SuccessModal from '../Modals/SuccessModal';
import './UserList.css';

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [filter, setFilter] = useState('All');
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  useEffect(() => {
    const fetchUsersData = async () => {
      try {
        const response = await fetchUsers();
        if (response.status === 'success') {
          setUsers(response.users);
        } else {
          alert('Failed to fetch users');
        }
      } catch (error) {
        // Handle error appropriately
      }
    };

    fetchUsersData();

    const interval = setInterval(() => {
      fetchUsersData();
    }, 100);

    return () => clearInterval(interval);
  }, []);

  const handleSelectUser = (user) => {
    setSelectedUser(user);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const handleDeleteUser = async () => {
    try {
      const response = await deleteUser(userToDelete.id);
      if (response.status === 'success') {
        setUsers(users.filter(user => user.id !== userToDelete.id));
        setIsSuccessModalOpen(true);
      } else {
        alert('Failed to delete user');
      }
    } catch (error) {
      alert('Failed to delete user');
    } finally {
      setIsConfirmationModalOpen(false);
    }
  };
  

  const handleOpenConfirmationModal = (user) => {
    setUserToDelete(user);
    setIsConfirmationModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
    setUserToDelete(null);
  };

  const handleCloseSuccessModal = () => {
    setIsSuccessModalOpen(false);
  };

  const filteredUsers = users.filter(user => filter === 'All' || user.status === filter);

  return (
    <div className="user-list-container">
      {selectedUser ? (
        <UserDetails user={selectedUser} onClose={() => setSelectedUser(null)} />
      ) : (
        <div>
          <div className="filter-container">
            <label htmlFor="filter" className="filter-label">Filter by Status:</label>
            <select id="filter" value={filter} onChange={handleFilterChange} className="filter-select">
              <option value="All">All</option>
              <option value="Active">Active</option>
              <option value="Pending">Pending</option>
            </select>
          </div>
          <div className="user-list-table-wrapper">
            <table className="user-list-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Balance</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers.map(user => (
                  <tr key={user.id} className="user-list-row" onClick={() => handleSelectUser(user)}>
                    <td>{user.id}</td>
                    <td>{user.first_name}</td>
                    <td>{user.last_name}</td>
                    <td>{user.balance}</td>
                    <td>
                      <span className={`status ${user.status.toLowerCase()}`}>
                        {user.status}
                      </span>
                    </td>
                    <td>
                      <button 
                        className="bg-red-600 hover:bg-red-700 text-white font-bold py-1 px-3 rounded"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenConfirmationModal(user);
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      <ConfirmationModal 
        isOpen={isConfirmationModalOpen}
        onClose={handleCloseConfirmationModal}
        onConfirm={handleDeleteUser}
        message="Are you sure you want to delete this user?"
      />
      <SuccessModal 
        isOpen={isSuccessModalOpen}
        onClose={handleCloseSuccessModal}
        message="User deleted successfully!"
      />
    </div>
  );
};

export default UserList;
