import React, { useState, useEffect, useRef } from 'react';
import { FaTimes } from 'react-icons/fa';
import Toastify from 'toastify-js';
import { fetchFirebaseInfo, setupFirebase, fetchCurrentProjectId, logoutFirebase } from '../services/ServerApi';
import './Section4Modal.css';
import 'toastify-js/src/toastify.css';
import HowToSetupModal from './HowToSetupModal';  // Import the new modal

const Section4Modal = ({ onClose }) => {
  const [firebaseInfo, setFirebaseInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [newFirebaseDetails, setNewFirebaseDetails] = useState({
    apiKey: '',
    authDomain: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  });
  const [currentProjectId, setCurrentProjectId] = useState(null);
  const [setupResponse, setSetupResponse] = useState(null);
  const [progress, setProgress] = useState(0);
  const [showHowToSetup, setShowHowToSetup] = useState(false);
  const fetchToastShown = useRef(false);

  useEffect(() => {
    if (!fetchToastShown.current) {
      fetchFirebaseInfoData();
      fetchCurrentProjectIdData();
      fetchToastShown.current = true;
    }
  }, []);

  const fetchFirebaseInfoData = async () => {
    Toastify({
      text: "Fetching Firebase information..."
    }).showToast();

    try {
      const data = await fetchFirebaseInfo();
      setFirebaseInfo(data);
      setLoading(false);

      Toastify({
        text: "Firebase information fetched successfully"
      }).showToast();
    } catch (error) {
      console.error('Error fetching Firebase information:', error);
      setLoading(false);

      Toastify({
        text: "Error fetching Firebase information",
        backgroundColor: "linear-gradient(to right, #ff5f6d, #ffc371)"
      }).showToast();
    }
  };

  const fetchCurrentProjectIdData = async () => {
    try {
      const data = await fetchCurrentProjectId();
      setCurrentProjectId(data.projectId);
    } catch (error) {
      console.error('Error fetching current project ID:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewFirebaseDetails({ ...newFirebaseDetails, [name]: value });
  };

  const handleSetupFirebase = async () => {
    setProgress(0);

    Toastify({
      text: "Setting up Firebase..."
    }).showToast();

    try {
      if (currentProjectId && currentProjectId !== newFirebaseDetails.projectId) {
        Toastify({
          text: "Project ID mismatch. Logging out current session and setting up new project.",
          backgroundColor: "linear-gradient(to right, #ff5f6d, #ffc371)"
        }).showToast();
      }

      const response = await setupFirebase(newFirebaseDetails);
      console.log('Response from setup_firebase.php:', response);

      if (response.success) {
        Toastify({
          text: "Firebase setup and rules deployment completed"
        }).showToast();
        setProgress(100);
      } else {
        Toastify({
          text: response.message || "Error deploying Firebase rules",
          backgroundColor: "linear-gradient(to right, #ff5f6d, #ffc371)"
        }).showToast();
        console.error('Deployment output:', response.output);
      }

      setSetupResponse(response);
    } catch (error) {
      console.error('Error setting up Firebase:', error);
      setSetupResponse({ error: 'Failed to setup Firebase' });

      Toastify({
        text: "Error setting up Firebase",
        backgroundColor: "linear-gradient(to right, #ff5f6d, #ffc371)"
      }).showToast();
    }
  };

  const handleLogout = async () => {
    try {
      await logoutFirebase();
      Toastify({
        text: "Logged out from Firebase CLI",
        backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)"
      }).showToast();
    } catch (error) {
      console.error('Error logging out from Firebase CLI:', error);
      Toastify({
        text: "Error logging out from Firebase CLI",
        backgroundColor: "linear-gradient(to right, #ff5f6d, #ffc371)"
      }).showToast();
    }
  };

  const handleShowHowToSetup = () => {
    setShowHowToSetup(true);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="modal-overlay-section4">
      <div className="modal-container-section4">
        <div className="modal-header-section4">
          <h2>Firebase Setup</h2>
          <button className="close-button-section4" onClick={onClose}>
            <FaTimes />
          </button>
        </div>
        <div className="modal-content-section4">
          <div className="box-section4">
            <h3>Setup New Firebase</h3>
            <input type="text" name="apiKey" placeholder="API Key" value={newFirebaseDetails.apiKey} onChange={handleInputChange} />
            <input type="text" name="authDomain" placeholder="Auth Domain" value={newFirebaseDetails.authDomain} onChange={handleInputChange} />
            <input type="text" name="projectId" placeholder="Project ID" value={newFirebaseDetails.projectId} onChange={handleInputChange} />
            <input type="text" name="storageBucket" placeholder="Storage Bucket" value={newFirebaseDetails.storageBucket} onChange={handleInputChange} />
            <input type="text" name="messagingSenderId" placeholder="Messaging Sender ID" value={newFirebaseDetails.messagingSenderId} onChange={handleInputChange} />
            <input type="text" name="appId" placeholder="App ID" value={newFirebaseDetails.appId} onChange={handleInputChange} />
            <button onClick={handleSetupFirebase}>Setup Firebase</button>
            <div className="progress-bar-section4">
              <div className="progress" style={{ width: `${progress}%` }}></div>
            </div>
            {setupResponse && (
              <div className="setup-response">
                {setupResponse.error ? (
                  <span className="error">{setupResponse.error}</span>
                ) : (
                  <span className="success">Firebase setup successful!</span>
                )}
              </div>
            )}
          </div>
          <div className="box-section4">
            <h3>Current Firebase Info</h3>
            {currentProjectId && (
              <p>Current Project ID: {currentProjectId}</p>
            )}
            {firebaseInfo && Object.keys(firebaseInfo).length > 0 ? (
              <pre>{JSON.stringify(firebaseInfo, null, 2)}</pre>
            ) : (
              <p>No Firebase information found.</p>
            )}
            <div className="box-section4">
            <button onClick={handleLogout}>Logout from Firebase CLI</button>
          </div>
          <div className="box-section4">
            <button onClick={handleShowHowToSetup}>How to Setup Firebase</button>
          </div>
          </div>          
        </div>
      </div>
      {showHowToSetup && <HowToSetupModal onClose={() => setShowHowToSetup(false)} />}
    </div>
  );
};

export default Section4Modal;
