import React, { useState } from 'react';
import { Bar, Line, Pie, Doughnut, PolarArea } from 'react-chartjs-2';
import { FaClock, FaBolt, FaBug } from 'react-icons/fa';
import {
  barChartData, barChartOptions,
  lineChartData, lineChartOptions,
  pieChartData, pieChartOptions,
  doughnutChartData, doughnutChartOptions,
  polarAreaChartData, polarAreaChartOptions
} from '../chartConfig';
import './ApplicationPerformanceGroup.css';

const sections = [
  { id: 'response-times', label: 'Response Times', icon: FaClock },
  { id: 'request-rates', label: 'Request Rates', icon: FaBolt },
  { id: 'error-rates', label: 'Error Rates', icon: FaBug },
  { id: 'uptime-downtime', label: 'Uptime/Downtime', icon: FaClock },
  { id: 'network-activity', label: 'Network Activity', icon: FaClock }
];

const ApplicationPerformanceGroup = () => {
  const [activeSection, setActiveSection] = useState(sections[0].id);

  const renderContent = () => {
    switch (activeSection) {
      case 'response-times':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center">
              <FaClock className="mr-2" /> Response Times
            </h2>
            <Line data={lineChartData} options={lineChartOptions} />
          </div>
        );
      case 'request-rates':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center">
              <FaBolt className="mr-2" /> Request Rates
            </h2>
            <Bar data={barChartData} options={barChartOptions} />
          </div>
        );
      case 'error-rates':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center">
              <FaBug className="mr-2" /> Error Rates
            </h2>
            <Pie data={pieChartData} options={pieChartOptions} />
          </div>
        );
      case 'uptime-downtime':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center">
              <FaClock className="mr-2" /> Uptime/Downtime
            </h2>
            <Doughnut data={doughnutChartData} options={doughnutChartOptions} />
          </div>
        );
      case 'network-activity':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center">
              <FaClock className="mr-2" /> Network Activity
            </h2>
            <PolarArea data={polarAreaChartData} options={polarAreaChartOptions} />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="application-performance-group bg-white shadow-lg rounded-lg p-6">
      <h2 className="text-2xl font-bold flex items-center mb-4">
        Application Performance Dashboard
      </h2>
      <div className="navigation mb-4">
        {sections.map((section) => (
          <button
            key={section.id}
            className={`nav-button ${activeSection === section.id ? 'active' : ''}`}
            onClick={() => setActiveSection(section.id)}
          >
            <section.icon className="mr-2" />
            {section.label}
          </button>
        ))}
      </div>
      <div className="content">{renderContent()}</div>
    </div>
  );
};

export default ApplicationPerformanceGroup;
