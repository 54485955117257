import React, { useState, useEffect } from 'react';
import { FaBell, FaMicrochip, FaServer, FaHeartbeat } from 'react-icons/fa';
import { Bar, Line, Pie } from 'react-chartjs-2';
import { getServiceHealthMetrics } from '../../services/MonitorService';
import {
  barChartOptions,
  lineChartOptions,
  pieChartOptions
} from './chartConfig';
import './ServiceHealthGroup.css';

const sections = [
  { id: 'microservices-status', label: 'Microservices Status', icon: FaMicrochip },
  { id: 'third-party-status', label: 'Third-Party Status', icon: FaServer },
  { id: 'external-api-health', label: 'External API Health', icon: FaHeartbeat },
];

const ServiceHealthGroup = () => {
  const [activeSection, setActiveSection] = useState(sections[0].id);
  const [metricsData, setMetricsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        const data = await getServiceHealthMetrics();
        setMetricsData(data);
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch service health metrics:', error);
        setError('Failed to fetch data');
        setLoading(false);
      }
    };

    fetchMetrics();
  }, []);

  const renderContent = () => {
    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    switch (activeSection) {
      case 'microservices-status':
        return (
          <div className="chart-container">
            {metricsData.microservices_status && metricsData.microservices_status.length > 0 ? (
              <Bar
                data={{
                  labels: metricsData.microservices_status.map(item => item.name),
                  datasets: [{ data: metricsData.microservices_status.map(item => item.status), backgroundColor: 'rgba(75, 192, 192, 0.2)', borderColor: 'rgba(75, 192, 192, 1)', borderWidth: 1 }]
                }}
                options={barChartOptions}
              />
            ) : (
              <div>No data available</div>
            )}
          </div>
        );
      case 'third-party-status':
        return (
          <div className="chart-container">
            {metricsData.third_party_status && metricsData.third_party_status.length > 0 ? (
              <Line
                data={{
                  labels: metricsData.third_party_status.map(item => item.name),
                  datasets: [{ data: metricsData.third_party_status.map(item => item.status), fill: false, borderColor: 'rgba(75, 192, 192, 1)', tension: 0.1 }]
                }}
                options={lineChartOptions}
              />
            ) : (
              <div>No data available</div>
            )}
          </div>
        );
      case 'external-api-health':
        return (
          <div className="chart-container">
            {metricsData.external_api_health && metricsData.external_api_health.length > 0 ? (
              <Pie
                data={{
                  labels: metricsData.external_api_health.map(item => item.name),
                  datasets: [{ data: metricsData.external_api_health.map(item => item.health), backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(153, 102, 255, 0.2)', 'rgba(255, 159, 64, 0.2)'], borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)', 'rgba(255, 159, 64, 1)'], borderWidth: 1 }]
                }}
                options={pieChartOptions}
              />
            ) : (
              <div>No data available</div>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="service-health-group bg-white shadow-lg rounded-lg p-6">
      <h2 className="text-2xl font-bold flex items-center mb-4">
        <FaBell className="mr-2 text-red-500" />
        Service Health Dashboard
      </h2>
      <div className="navigation mb-4">
        {sections.map((section) => (
          <button
            key={section.id}
            className={`nav-button ${activeSection === section.id ? 'active' : ''}`}
            onClick={() => setActiveSection(section.id)}
          >
            <section.icon className="mr-2" />
            {section.label}
          </button>
        ))}
      </div>
      <div className="content">{renderContent()}</div>
    </div>
  );
};

export default ServiceHealthGroup;
