// src/components/Modals/ErrorModal.js
import React from 'react';
import { FaTimes } from 'react-icons/fa';
import './modal.css';

const ErrorModal = ({ isOpen, onClose, errorMessage }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h2 className="text-2xl font-semibold text-red-600">Error</h2>
          <button onClick={onClose}>
            <FaTimes />
          </button>
        </div>
        <div className="modal-body">
          <p>{errorMessage}</p>
          <div className="modal-footer">
            <button
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300 ease-in-out"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorModal;
