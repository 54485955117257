import axios from 'axios';

const API_BASE_URL = 'https://homeaidrelief.com/api';

// Helper function to create API endpoint URL
const createApiUrl = (endpoint) => `${API_BASE_URL}/${endpoint}.php`;

const getToken = () => {
  return localStorage.getItem('token');
};

// Create an Axios instance with an interceptor to include the JWT token
const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

export const getSeoSettings = async (pageUrl) => {
  try {
    const response = await api.get(createApiUrl('Seo/get-seo-settings'), { params: { pageUrl } });
    return response.data;
  } catch (error) {
    console.error('Error fetching SEO settings:', error);
    throw error;
  }
};

export const saveSeoSettings = async (seoData) => {
  console.log('API request to save SEO settings:', seoData);
  try {
    const response = await api.post(createApiUrl('Seo/save-seo-settings'), seoData);
    console.log('API response from saving SEO settings:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error saving SEO settings:', error);
    throw error;
  }
};

export const uploadImage = async (formData) => {
  try {
    const response = await api.post(createApiUrl('Seo/upload-image'), formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};
