import React, { useState } from 'react';
import { FaBars, FaBell, FaServer, FaGlobe, FaDesktop } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import useNotifications from '../../hooks/useNotifications';
import ServerInfoModal from '../Modals/ServerInfoModal';
import SEOModal from '../Modals/SEOModal';
import MonitorModal from '../Modals/MonitorModal'; // Import MonitorModal
import './HeaderNav.css';

const HeaderNav = ({ toggleSideNav }) => {
  const navigate = useNavigate();
  const { notifications, unreadCount, markAsRead } = useNotifications();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showServerInfo, setShowServerInfo] = useState(false);
  const [showSEOModal, setShowSEOModal] = useState(false);
  const [showMonitorModal, setShowMonitorModal] = useState(false); // State for MonitorModal

  const handleBellClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handleServerInfoClick = () => {
    setShowServerInfo(true);
  };

  const handleSEOClick = () => {
    setShowSEOModal(true);
  };

  const handleMonitorClick = () => {
    setShowMonitorModal(true); // Open MonitorModal
  };

  return (
    <header className="bg-gray-50 shadow fixed top-0 left-0 right-0 z-50">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center h-16">
        <div className="flex items-center">
          <h1 className="text-xl font-bold text-gray-700">Admin Dashboard</h1>
          <div>
            <p>- | -</p>
          </div>
          <button
            className="bg-gray-200 p-2 rounded-md inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-300 focus:outline-none focus:bg-gray-300 focus:text-gray-500 mr-4"
            onClick={toggleSideNav}
          >
            <FaBars className="h-6 w-6" />
          </button>
        </div>
        <div className="flex items-center space-x-4">
          <button
            className="flex items-center bg-white rounded-full shadow-md px-4 py-2"
            onClick={handleServerInfoClick}
          >
            <FaServer className="text-gray-400 mr-2" />
            <span className="text-gray-700">Server Info</span>
          </button>
          <button
            className="flex items-center bg-white rounded-full shadow-md px-4 py-2"
            onClick={handleSEOClick}
          >
            <FaGlobe className="text-gray-400 mr-2" />
            <span className="text-gray-700">SEO</span>
          </button>
          <button
            className="flex items-center bg-white rounded-full shadow-md px-4 py-2"
            onClick={handleMonitorClick}
          >
            <FaDesktop className="text-gray-400 mr-2" />
            <span className="text-gray-700">Monitor</span>
          </button>
          <div className="relative">
            <div onClick={handleBellClick} className="cursor-pointer flex items-center">
              <FaBell className="text-gray-700 h-6 w-6 animated-bell" />
              {unreadCount > 0 && (
                <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
                  {unreadCount}
                </span>
              )}
            </div>
            {showDropdown && (
              <div className="absolute right-0 mt-2 w-64 max-h-96 overflow-auto bg-white bg-opacity-90 rounded-lg shadow-lg z-10">
                <div className="p-4">
                  <h3 className="text-lg font-bold text-gray-700">Notifications</h3>
                  <ul className="mt-2">
                    {notifications.length === 0 ? (
                      <li className="p-2 text-gray-500">No notifications available</li>
                    ) : (
                      notifications.map(notification => (
                        <li 
                          key={notification.id} 
                          className={`notification-item ${notification.is_read ? 'read' : 'unread'} p-2 border-b border-gray-200 cursor-pointer flex justify-between items-center`} 
                          onClick={() => markAsRead(notification.id)}
                        >
                          <div>
                            <p>{notification.first_name} {notification.last_name}: {notification.message}</p>
                            <small className="block text-xs text-gray-500">{new Date(notification.created_at).toLocaleString()}</small>
                          </div>
                          {!notification.is_read && <span className="h-2 w-2 bg-green-500 rounded-full ml-2"></span>}
                        </li>
                      ))
                    )}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="sm:hidden">
          <button className="bg-gray-200 p-2 rounded-md inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-300 focus:outline-none focus:bg-gray-300 focus:text-gray-500">
            <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
      </div>
      {showServerInfo && <ServerInfoModal onClose={() => setShowServerInfo(false)} />}
      {showSEOModal && <SEOModal pageUrl={window.location.pathname} onClose={() => setShowSEOModal(false)} />} {/* SEO Modal */}
      {showMonitorModal && <MonitorModal onClose={() => setShowMonitorModal(false)} />} {/* Monitor Modal */}
    </header>
  );
};

export default HeaderNav;
