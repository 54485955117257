import React from 'react';
import { Link } from 'react-scroll';
import { FaChartLine, FaTools, FaBell, FaSearch } from 'react-icons/fa';
import './MonitorNav.css';

const MonitorNavBottom = ({ setActiveSection }) => {
  const navItems = [
    { id: 'service-health', label: 'Service Health', icon: <FaChartLine /> },
    { id: 'deployment-metrics', label: 'Deployment Metrics', icon: <FaTools /> },
    { id: 'real-time-notifications', label: 'Real-time Notifications', icon: <FaBell /> },
    { id: 'seo-metrics', label: 'SEO Metrics', icon: <FaSearch /> },
  ];

  const handleClick = (id) => {
    setActiveSection(id);
  };

  return (
    <div className="monitor-nav monitor-nav-bottom">
      <div className="monitor-nav-container">
        {navItems.map(item => (
          <Link
            key={item.id}
            to={item.id}
            smooth={true}
            duration={500}
            className="monitor-nav-item"
            onClick={() => handleClick(item.id)}
          >
            {item.icon} <span>{item.label}</span>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default MonitorNavBottom;
