import axios from 'axios';

const API_URL = 'https://homeaidrelief.com/api';

// Helper function to create API endpoint URL
const createApiUrl = (endpoint) => `${API_URL}/${endpoint}.php`;

const getToken = () => {
  return localStorage.getItem('token');
};

// Create an Axios instance with an interceptor to include the JWT token
const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

// Fetch Server Information
export const fetchServerInfo = async () => {
  try {
    const response = await api.get(createApiUrl('Server/ServerInfo'));
    return response.data;
  } catch (error) {
    console.error('Error fetching server information:', error);
    throw error;
  }
};

// Fetch Table Information
export const fetchTableInfo = async () => {
  try {
    const response = await api.get(createApiUrl('Database/table_info'));
    return response.data;
  } catch (error) {
    console.error('Error fetching table information:', error);
    throw error;
  }
};

// Fetch Database Size
export const fetchDatabaseSize = async () => {
  try {
    const response = await api.get(createApiUrl('Database/database_size'));
    return response.data;
  } catch (error) {
    console.error('Error fetching database size:', error);
    throw error;
  }
};

// Fetch Database Info
export const fetchDatabaseInfo = async () => {
  try {
    const response = await api.get(createApiUrl('Database/fetch_db_info'));
    return response.data;
  } catch (error) {
    console.error('Error fetching database info:', error);
    throw error;
  }
};

// Setup Database
export const setupDatabase = async (dbDetails) => {
  try {
    const response = await api.post(createApiUrl('Database/setup_database'), dbDetails);
    return response.data;
  } catch (error) {
    console.error('Error setting up database:', error);
    throw error;
  }
};

// Fetch Firebase Information
export const fetchFirebaseInfo = async () => {
  try {
    const response = await api.get(createApiUrl('Database/fetch_firebase_info'));
    return response.data;
  } catch (error) {
    console.error('Error fetching Firebase info:', error);
    throw error;
  }
};

export const fetchCurrentProjectId = async () => {
  try {
    const response = await api.get(createApiUrl('Database/get_current_project_id'));
    return response.data;
  } catch (error) {
    console.error('Error fetching current project ID:', error);
    throw error;
  }
};

export const logoutFirebase = async () => {
  try {
    const response = await api.post(createApiUrl('Database/logout_firebase'));
    return response.data;
  } catch (error) {
    console.error('Error logging out of Firebase:', error);
    throw error;
  }
};

// Setup Firebase
export const setupFirebase = async (firebaseDetails) => {
  console.log('Setting up Firebase...');
  console.log('Firebase details:', firebaseDetails);
  try {
    const response = await api.post(createApiUrl('Database/setup_firebase'), firebaseDetails);
    console.log('Firebase setup response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error setting up Firebase:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    } else if (error.request) {
      console.error('Request data:', error.request);
    } else {
      console.error('Error message:', error.message);
    }
    throw error;
  }
};
