// src/components/Monitor/ServerHealthMetrics/ServerHealthMetricsGroup.js
import React, { useState, useEffect } from 'react';
import { FaMicrochip, FaMemory, FaHdd, FaNetworkWired } from 'react-icons/fa';
import { PolarArea, Doughnut, Radar, Bubble } from 'react-chartjs-2';
import { getServerHealthMetrics } from '../../services/MonitorService';
import {
  polarAreaChartOptions,
  doughnutChartOptions,
  radarChartOptions,
  bubbleChartOptions,
} from './chartConfig';
import './ServerHealthMetricsGroup.css';

const sections = [
  { id: 'cpu-usage', label: 'CPU Usage', icon: FaMicrochip },
  { id: 'memory-usage', label: 'Memory Usage', icon: FaMemory },
  { id: 'disk-usage', label: 'Disk Usage', icon: FaHdd },
  { id: 'network-activity', label: 'Network Activity', icon: FaNetworkWired }
];

const ServerHealthMetricsGroup = () => {
  const [activeSection, setActiveSection] = useState(sections[0].id);
  const [metricsData, setMetricsData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        const data = await getServerHealthMetrics();
        setMetricsData(data);
      } catch (error) {
        setError('Failed to fetch server health metrics');
      }
    };

    fetchMetrics();
  }, []);

  const renderContent = () => {
    if (error) {
      return <div className="error-message">{error}</div>;
    }

    if (!metricsData) {
      return <div>Loading...</div>;
    }

    switch (activeSection) {
      case 'cpu-usage':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center mb-4">
              <FaMicrochip className="mr-2" /> CPU Usage
            </h2>
            <PolarArea 
              data={{
                labels: ['1 min'],
                datasets: [{ 
                  data: [metricsData.cpu_usage], 
                  backgroundColor: ['rgba(75, 192, 192, 0.2)'],
                  borderColor: ['rgba(75, 192, 192, 1)'],
                  borderWidth: 1
                }]
              }} 
              options={polarAreaChartOptions} 
            />
            <div>Current CPU load over the past minute.</div>
          </div>
        );
      case 'memory-usage':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center mb-4">
              <FaMemory className="mr-2" /> Memory Usage
            </h2>
            <Doughnut 
              data={{
                labels: ['Used', 'Free'],
                datasets: [{ 
                  data: [metricsData.memory_usage, 100 - metricsData.memory_usage],
                  backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)'],
                  borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
                  borderWidth: 1
                }]
              }} 
              options={doughnutChartOptions} 
            />
            <div>Percentage of memory used vs free memory.</div>
          </div>
        );
      case 'disk-usage':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center mb-4">
              <FaHdd className="mr-2" /> Disk Usage
            </h2>
            <Radar 
              data={{
                labels: ['Used', 'Free'],
                datasets: [{ 
                  data: [metricsData.disk_usage, 100 - metricsData.disk_usage],
                  backgroundColor: 'rgba(75, 192, 192, 0.2)',
                  borderColor: 'rgba(75, 192, 192, 1)',
                  borderWidth: 1
                }]
              }} 
              options={radarChartOptions} 
            />
            <div>Disk usage percentage.</div>
          </div>
        );
      case 'network-activity':
        const networkActivity = metricsData.network_activity || { rx: 0, tx: 0 };
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center mb-4">
              <FaNetworkWired className="mr-2" /> Network Activity
            </h2>
            <Bubble 
              data={{
                datasets: [{
                  data: [
                    { x: 0, y: networkActivity.rx, r: 10 }, 
                    { x: 1, y: networkActivity.tx, r: 10 }
                  ],
                  backgroundColor: 'rgba(153, 102, 255, 0.2)',
                  borderColor: 'rgba(153, 102, 255, 1)',
                  borderWidth: 1
                }]
              }} 
              options={bubbleChartOptions} 
            />
            <div>Bytes received (rx) and transmitted (tx) over the network.</div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="server-health-metrics-group bg-white shadow-lg rounded-lg p-6">
      <h2 className="text-2xl font-bold flex items-center mb-4">
        Server Health Metrics Dashboard
      </h2>
      <div className="navigation mb-4">
        {sections.map((section) => (
          <button
            key={section.id}
            className={`nav-button ${activeSection === section.id ? 'active' : ''}`}
            onClick={() => setActiveSection(section.id)}
          >
            <section.icon className="mr-2" />
            {section.label}
          </button>
        ))}
      </div>
      <div className="content">{renderContent()}</div>
    </div>
  );
};

export default ServerHealthMetricsGroup;
