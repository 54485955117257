// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Monitor from './pages/Monitor';
import PrivateRoute from './components/Routes/PrivateRoute';
import { useAuth } from './context/AuthContext';
import useActivityTracker from './hooks/useActivityTracker';
import LogoutModal from './components/Modals/LogoutModal';
import NotFound from './components/NotFound';
import ErrorBoundary from './ErrorBoundary';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const [loading, setLoading] = useState(true);
  const { isAuthenticated, logout } = useAuth();
  const { showLogoutModal, countdown, setShowLogoutModal, isAutoLogout } = useActivityTracker(300000); // 1 minute

  useEffect(() => {
    setLoading(false);
  }, []);

  const handleLogout = () => {
    setShowLogoutModal(false);
    logout();
  };

  if (loading) {
    return <div>Loading...</div>; // Or any loading indicator
  }

  return (
    <ErrorBoundary>
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/dashboard/*"
            element={
              <PrivateRoute isAuthenticated={isAuthenticated}>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/monitor"
            element={
              <PrivateRoute isAuthenticated={isAuthenticated}>
                <Monitor />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<NotFound />} /> {/* Catch-all route */}
        </Routes>
        {showLogoutModal && (
          <LogoutModal
            onClose={() => setShowLogoutModal(false)}
            onConfirm={handleLogout}
            countdown={countdown}
            isAutoLogout={isAutoLogout}
          />
        )}
      </Router>
      <ToastContainer />
    </ErrorBoundary>
  );
};

export default App;
