import React, { useState, useEffect } from 'react';
import { fetchMessages, replyMessage, deleteMessage } from '../components/services/api';
import { FaReply, FaTrash } from 'react-icons/fa';
import ReplyModal from '../components/Modals/ReplyModal';

const Messages = () => {
  const [messages, setMessages] = useState([]);
  const [replyData, setReplyData] = useState({
    user_id: null,
    original_message: '',
    reply_message: '',
    original_message_id: null,
    category: '',
    subcategory: '',
    attachment: '',
    timestamp: '',
    status: ''
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [showReplyModal, setShowReplyModal] = useState(false);

  useEffect(() => {
    fetchMessagesData();
  }, []);

  const fetchMessagesData = async () => {
    setLoading(true);
    try {
      const response = await fetchMessages();
      if (Array.isArray(response)) {
        setMessages(response);
      } else {
        console.error('Unexpected response format for messages:', response);
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
    setLoading(false);
  };

  const handleReply = async () => {
    setLoading(true);
    try {
      const response = await replyMessage(replyData);
      fetchMessagesData();
      setReplyData({
        user_id: null,
        original_message: '',
        reply_message: '',
        original_message_id: null,
        category: '',
        subcategory: '',
        attachment: '',
        timestamp: '',
        status: ''
      });
      setMessage('Reply sent successfully');
      setShowReplyModal(false);
    } catch (error) {
      console.error('Error replying to message:', error);
      setMessage('Error sending reply');
    }
    setLoading(false);
  };

  const handleDeleteMessage = async (id) => {
    setLoading(true);
    try {
      const response = await deleteMessage(id);
      fetchMessagesData();
      setMessage('Message deleted successfully');
    } catch (error) {
      console.error('Error deleting message:', error);
      setMessage('Error deleting message');
    }
    setLoading(false);
  };

  const closeModal = () => {
    setShowReplyModal(false);
    setReplyData({
      user_id: null,
      original_message: '',
      reply_message: '',
      original_message_id: null,
      category: '',
      subcategory: '',
      attachment: '',
      timestamp: '',
      status: ''
    });
  };

  return (
    <div className="min-h-screen bg-gray-100 p-4">
      <h1 className="text-2xl font-bold mb-4">Messages</h1>
      {message && <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4" role="alert">{message}</div>}
      {loading ? (
        <div className="text-center py-6">Loading...</div>
      ) : (
        <div className="bg-white shadow-md rounded mb-4 overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr className="bg-gray-200">
                <th className="w-1/6 py-2">Message ID</th>
                <th className="w-1/6 py-2">Category</th>
                <th className="w-1/6 py-2">Subcategory</th>
                <th className="w-1/6 py-2">Attachment</th>
                <th className="w-1/6 py-2">Timestamp</th>
                <th className="w-1/6 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(messages) && messages.map((message) => (
                <React.Fragment key={message.id}>
                  <tr key={message.id} className="hover:bg-gray-100 transition duration-200">
                    <td className="border px-4 py-2">{message.user_id}</td>
                    <td className="border px-4 py-2">{message.category}</td>
                    <td className="border px-4 py-2">{message.subcategory}</td>
                    <td className="border px-4 py-2">
                      {message.attachment ? (
                        <a href={message.attachment} download>
                          Download
                        </a>
                      ) : (
                        'No Attachment'
                      )}
                    </td>
                    <td className="border px-4 py-2">{message.timestamp}</td>
                    <td className="border px-4 py-2 flex space-x-2">
                      <button
                        className="bg-yellow-500 text-white px-4 py-2 rounded shadow-lg hover:bg-yellow-600 transition duration-200"
                        onClick={() => {
                          setReplyData({
                            user_id: message.user_id,
                            original_message: message.message,
                            reply_message: '',
                            original_message_id: message.id,
                            category: message.category,
                            subcategory: message.subcategory,
                            attachment: message.attachment,
                            timestamp: message.timestamp,
                            status: 'replied'
                          });
                          setShowReplyModal(true);
                        }}
                      >
                        <FaReply />
                      </button>
                      <button
                        className="bg-red-500 text-white px-4 py-2 rounded shadow-lg hover:bg-red-600 transition duration-200"
                        onClick={() => handleDeleteMessage(message.id)}
                      >
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                  <tr key={`message-${message.id}`} className="bg-gray-100">
                    <td colSpan="6" className="border px-4 py-2">
                      <strong>Message:</strong> {message.message}
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {showReplyModal && (
        <ReplyModal
          replyData={replyData}
          setReplyData={setReplyData}
          onClose={closeModal}
          onSubmit={handleReply}
        />
      )}
    </div>
  );
};

export default Messages;
