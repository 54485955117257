import React from 'react';
import { FaTimes } from 'react-icons/fa';

const Modal = ({ title, children, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center backdrop-filter backdrop-blur-sm transition-opacity duration-300 ease-in-out z-50">
      <div className="bg-white p-6 rounded-lg shadow-2xl w-96 transform transition-transform duration-300 ease-in-out scale-100">
        <div className="flex justify-between items-center mb-4 border-b pb-2">
          <h2 className="text-2xl font-semibold text-gray-800">{title}</h2>
          <button 
            onClick={onClose} 
            className="text-gray-500 hover:text-red-500 transition duration-200"
            aria-label="Close"
          >
            <FaTimes size={20} />
          </button>
        </div>
        <div className="mt-4">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
