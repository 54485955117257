import React from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';

const AuthModal = ({ isOpen, onRequestClose, title, message, code }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(code);
    toast.success('Code copied to clipboard!');
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Auth Modal"
      className="relative z-50 p-8 bg-white rounded-lg shadow-lg w-full max-w-md mx-auto"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center backdrop-filter backdrop-blur-sm"
    >
      <h2 className="text-3xl mb-6 text-center font-semibold">{title}</h2>
      <div className="p-6 border rounded-lg bg-yellow-100 text-center">
        <p className="font-bold text-2xl">{code}</p>
        <p className="mt-4 text-red-600 font-medium">{message}</p>
      </div>
      <div className="mt-6 flex justify-center space-x-4">
        <button
          onClick={copyToClipboard}
          className="py-2 px-6 rounded bg-green-500 hover:bg-green-700 text-white font-bold"
        >
          Copy Code
        </button>
        <button
          onClick={onRequestClose}
          className="py-2 px-6 rounded bg-blue-500 hover:bg-blue-700 text-white font-bold"
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

export default AuthModal;
