import React, { useState, useEffect } from 'react';
import { Bar, Line, Pie, Doughnut, Radar } from 'react-chartjs-2';
import { FaDatabase } from 'react-icons/fa';
import { getDatabasePerformanceMetrics } from '../../services/MonitorService';
import {
  barChartOptions,
  lineChartOptions,
  pieChartOptions,
  doughnutChartOptions,
  radarChartOptions
} from './chartConfig';
import './DatabasePerformanceGroup.css';

const sections = [
  { id: 'query-response-times', label: 'Query Response Times', icon: FaDatabase },
  { id: 'connection-pool-usage', label: 'Connection Pool Usage', icon: FaDatabase },
  { id: 'slow-queries', label: 'Slow Queries', icon: FaDatabase },
  { id: 'database-size', label: 'Database Size', icon: FaDatabase },
  { id: 'index-performance', label: 'Index Performance', icon: FaDatabase }
];

const DatabasePerformanceGroup = () => {
  const [activeSection, setActiveSection] = useState(sections[0].id);
  const [metricsData, setMetricsData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        const data = await getDatabasePerformanceMetrics();
        setMetricsData(data);
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch database performance metrics:', error);
        setError('Failed to fetch data');
        setLoading(false);
      }
    };

    fetchMetrics();
  }, []);

  const renderContent = () => {
    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    const noDataMessage = <div>No Data Available</div>;

    switch (activeSection) {
      case 'query-response-times':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center">
              <FaDatabase className="mr-2" /> Query Response Times
            </h2>
            {metricsData.query_response_times && metricsData.query_response_times.length > 0 ? (
              <Bar
                data={{
                  labels: metricsData.query_response_times.map(item => item.query),
                  datasets: [{ data: metricsData.query_response_times.map(item => item.response_time), backgroundColor: 'rgba(75, 192, 192, 0.2)', borderColor: 'rgba(75, 192, 192, 1)', borderWidth: 1 }]
                }}
                options={barChartOptions}
              />
            ) : noDataMessage}
          </div>
        );
      case 'connection-pool-usage':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center">
              <FaDatabase className="mr-2" /> Connection Pool Usage
            </h2>
            {metricsData.connection_pool_usage && metricsData.connection_pool_usage.length > 0 ? (
              <Line
                data={{
                  labels: metricsData.connection_pool_usage.map(item => item.timestamp),
                  datasets: [{ data: metricsData.connection_pool_usage.map(item => item.pool_size), fill: false, borderColor: 'rgba(75, 192, 192, 1)', tension: 0.1 }]
                }}
                options={lineChartOptions}
              />
            ) : noDataMessage}
          </div>
        );
      case 'slow-queries':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center">
              <FaDatabase className="mr-2" /> Slow Queries
            </h2>
            {metricsData.slow_queries && metricsData.slow_queries.length > 0 ? (
              <Pie
                data={{
                  labels: metricsData.slow_queries.map(item => item.query),
                  datasets: [{ data: metricsData.slow_queries.map(item => item.duration), backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(153, 102, 255, 0.2)', 'rgba(255, 159, 64, 0.2)'], borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)', 'rgba(255, 159, 64, 1)'], borderWidth: 1 }]
                }}
                options={pieChartOptions}
              />
            ) : noDataMessage}
          </div>
        );
      case 'database-size':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center">
              <FaDatabase className="mr-2" /> Database Size
            </h2>
            {metricsData.database_size && metricsData.database_size.length > 0 ? (
              <Doughnut
                data={{
                  labels: metricsData.database_size.map(item => item.database_name),
                  datasets: [{ data: metricsData.database_size.map(item => item.size_in_mb), backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(153, 102, 255, 0.2)', 'rgba(255, 159, 64, 0.2)'], borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)', 'rgba(255, 159, 64, 1)'], borderWidth: 1 }]
                }}
                options={doughnutChartOptions(metricsData.database_size[0].size_in_mb + ' MB')}
              />
            ) : noDataMessage}
          </div>
        );
      case 'index-performance':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center">
              <FaDatabase className="mr-2" /> Index Performance
            </h2>
            {metricsData.index_performance && metricsData.index_performance.length > 0 ? (
              <Radar
                data={{
                  labels: metricsData.index_performance.map(item => item.index_name),
                  datasets: [{ data: metricsData.index_performance.map(item => item.performance), backgroundColor: 'rgba(75, 192, 192, 0.2)', borderColor: 'rgba(75, 192, 192, 1)', borderWidth: 1 }]
                }}
                options={radarChartOptions}
              />
            ) : noDataMessage}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="database-performance-group bg-white shadow-lg rounded-lg p-6">
      <h2 className="text-2xl font-bold flex items-center mb-4">
        <FaDatabase className="mr-2" /> Database Performance Dashboard
      </h2>
      <div className="navigation mb-4">
        {sections.map((section) => (
          <button
            key={section.id}
            className={`nav-button ${activeSection === section.id ? 'active' : ''}`}
            onClick={() => setActiveSection(section.id)}
          >
            <section.icon className="mr-2" />
            {section.label}
          </button>
        ))}
      </div>
      <div className="content">{renderContent()}</div>
    </div>
  );
};

export default DatabasePerformanceGroup;
