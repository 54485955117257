import React, { useState, useRef, useEffect } from 'react';
import Modal from 'react-modal';
import { FaLock, FaEye, FaEyeSlash } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SetupDatabaseModal from './SetupDatabaseModal';
import { verifyAuthPin } from '../services/authApi';
import { useNavigate } from 'react-router-dom';

Modal.setAppElement('#root');

const VerifyPinModal = ({ isOpen, onRequestClose }) => {
  const [pin, setPin] = useState(Array(6).fill(''));
  const [showPin, setShowPin] = useState(false);
  const [isDbSetupModalOpen, setIsDbSetupModalOpen] = useState(false);
  const inputRefs = useRef([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isOpen) {
      setPin(Array(6).fill(''));
    }
  }, [isOpen]);

  const handlePinChange = (value, index) => {
    const newPin = [...pin];
    newPin[index] = value;
    setPin(newPin);

    if (value && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !pin[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleVerify = async () => {
    try {
      const result = await verifyAuthPin(pin.join(''));
      if (result.success) {
        toast.success(result.message, {
          onClose: () => setIsDbSetupModalOpen(true)
        });
        localStorage.setItem('token', result.token); // Store the token in local storage
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(`Error verifying PIN: ${error.message}`);
    }
  };

  const handleDbSetup = async (dbDetails) => {
    toast.success('Database setup successful!', {
      onClose: () => {
        setIsDbSetupModalOpen(false); // Close the setup database modal
        onRequestClose(); // Close the verify PIN modal
        navigate('/login');
      },
      autoClose: 3000 // 3 seconds
    });
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <div className="p-4">
          <h2 className="text-2xl font-bold mb-4">Verify PIN</h2>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2" htmlFor="pin">
              Enter PIN
            </label>
            <div className="flex items-center border rounded py-2 px-3 shadow focus-within:shadow-outline">
              <FaLock className="text-gray-400 mr-2" />
              <div className="flex">
                {pin.map((digit, index) => (
                  <input
                    key={index}
                    type={showPin ? 'text' : 'password'}
                    maxLength="1"
                    value={digit}
                    onChange={(e) => handlePinChange(e.target.value, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    ref={(el) => (inputRefs.current[index] = el)}
                    className="w-10 text-center mx-1 border-b-2 border-gray-400 focus:border-blue-500 focus:outline-none"
                  />
                ))}
              </div>
              <button
                type="button"
                onClick={() => setShowPin(!showPin)}
                className="ml-2 focus:outline-none"
              >
                {showPin ? <FaEyeSlash className="text-gray-600" /> : <FaEye className="text-gray-600" />}
              </button>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <motion.button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={handleVerify}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Verify
            </motion.button>
          </div>
        </div>
      </Modal>
      <SetupDatabaseModal
        isOpen={isDbSetupModalOpen}
        onRequestClose={() => setIsDbSetupModalOpen(false)}
        onSetup={handleDbSetup}
      />
      <ToastContainer />
    </>
  );
};

export default VerifyPinModal;
