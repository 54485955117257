import axios from 'axios';

const API_BASE_URL = 'https://homeaidrelief.com/api/Monitor';

// Helper function to create API endpoint URL
const createApiUrl = (endpoint) => `${API_BASE_URL}/${endpoint}.php`;

// Helper function to get the token from local storage
const getToken = () => {
  return localStorage.getItem('token');
};

// Create an Axios instance with an interceptor to include the JWT token
const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

// Functions to fetch various metrics
export const getServerHealthMetrics = async () => {
  const API_URL = createApiUrl('getServerHealthMetrics');
  try {
    const response = await api.get(API_URL);
    return response.data;
  } catch (error) {
    console.error('Error fetching server health metrics:', error);
    throw error;
  }
};

export const getApplicationPerformanceMetrics = async () => {
  const API_URL = createApiUrl('getApplicationPerformanceMetrics');
  try {
    const response = await api.get(API_URL);
    return response.data;
  } catch (error) {
    console.error('Error fetching application performance metrics:', error);
    throw error;
  }
};

export const getDatabasePerformanceMetrics = async () => {
  const API_URL = createApiUrl('getDatabasePerformanceMetrics');
  try {
    const response = await api.get(API_URL);
    return response.data;
  } catch (error) {
    console.error('Error fetching database performance metrics:', error);
    throw error;
  }
};

export const getUserActivityMetrics = async () => {
  const API_URL = createApiUrl('getUserActivityMetrics');
  try {
    const response = await api.get(API_URL);
    return response.data;
  } catch (error) {
    console.error('Error fetching user activity metrics:', error);
    throw error;
  }
};

export const getErrorTrackingMetrics = async () => {
  const API_URL = createApiUrl('getErrorTrackingMetrics');
  try {
    const response = await api.get(API_URL);
    return response.data;
  } catch (error) {
    console.error('Error fetching error tracking metrics:', error);
    throw error;
  }
};

export const getSecurityMonitoringMetrics = async () => {
  const API_URL = createApiUrl('getSecurityMonitoringMetrics');
  try {
    const response = await api.get(API_URL);
    return response.data;
  } catch (error) {
    console.error('Error fetching security monitoring metrics:', error);
    throw error;
  }
};

export const getApplicationMetrics = async () => {
  const API_URL = createApiUrl('getApplicationMetrics');
  try {
    const response = await api.get(API_URL);
    return response.data;
  } catch (error) {
    console.error('Error fetching application metrics:', error);
    throw error;
  }
};

export const getResourceUsageMetrics = async () => {
  const API_URL = createApiUrl('getResourceUsageMetrics');
  try {
    const response = await api.get(API_URL);
    return response.data;
  } catch (error) {
    console.error('Error fetching resource usage metrics:', error);
    throw error;
  }
};

export const getServiceHealthMetrics = async () => {
  const API_URL = createApiUrl('getServiceHealthMetrics');
  try {
    const response = await api.get(API_URL);
    return response.data;
  } catch (error) {
    console.error('Error fetching service health metrics:', error);
    throw error;
  }
};

export const getDeploymentMetrics = async () => {
  const API_URL = createApiUrl('getDeploymentMetrics');
  try {
    const response = await api.get(API_URL);
    return response.data;
  } catch (error) {
    console.error('Error fetching deployment metrics:', error);
    throw error;
  }
};

export const getRealTimeNotificationsMetrics = async () => {
  const API_URL = createApiUrl('getRealTimeNotificationsMetrics');
  try {
    const response = await api.get(API_URL);
    return response.data;
  } catch (error) {
    console.error('Error fetching real-time notifications metrics:', error);
    throw error;
  }
};

export const getSeoMetrics = async () => {
  const API_URL = createApiUrl('getSeoMetrics');
  try {
    const response = await api.get(API_URL);
    return response.data;
  } catch (error) {
    console.error('Error fetching SEO metrics:', error);
    throw error;
  }
};
