import React, { useEffect, useState } from 'react';

const LogoutModal = ({ onClose, onConfirm, countdown: initialCountdown, isAutoLogout }) => {
  const [countdown, setCountdown] = useState(30);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 1) {
          clearInterval(timer);
          onConfirm();
        }
        return prevCountdown - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [onConfirm]);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg z-50">
        <h2 className="text-xl font-bold mb-4">Confirm Logout</h2>
        <p className="mb-4">{isAutoLogout ? "You have been inactive for a while." : "Are you sure you want to logout?"}</p>
        <p className="mb-4">Logging out in {countdown} seconds...</p>
        <div className="flex space-x-4">
          <button onClick={onConfirm} className="bg-red-500 text-white px-4 py-2 rounded">Yes, Logout</button>
          <button onClick={onClose} className="bg-gray-300 text-black px-4 py-2 rounded">No, Stay Logged In</button>
        </div>
      </div>
    </div>
  );
};

export default LogoutModal;
