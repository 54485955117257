// src/pages/Dashboard.js
import React, { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import HeaderNav from '../components/Navs/HeaderNav';
import SideNav from '../components/Navs/SideNav';
import Home from './Home';
import Announcements from '../pages/Announcements';
import News from './News';
import Vouchers from './Vouchers';
import Messages from '../pages/Messages';
import ServerInfo from '../components/Modals/ServerInfoModal';
import Settings from '../pages/Settings';
import Monitor from './Monitor'; // Import Monitor component

const Dashboard = () => {
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);

  const toggleSideNav = () => {
    setIsSideNavOpen(!isSideNavOpen);
  };

  return (
    <div className="min-h-screen bg-gray-100 pt-10 flex">
      <HeaderNav toggleSideNav={toggleSideNav} />
      <SideNav isOpen={isSideNavOpen} />
      <div className={`flex-1 container mx-auto px-4 py-8 transition-all duration-300 ${isSideNavOpen ? 'ml-52' : 'ml-16'}`}>
        <Routes>
          <Route path="/" element={<Navigate to="home" />} />
          <Route path="home" element={<Home />} />
          <Route path="announcements" element={<Announcements />} />
          <Route path="news" element={<News />} />
          <Route path="vouchers" element={<Vouchers />} />
          <Route path="messages" element={<Messages />} />
          <Route path="server-info" element={<ServerInfo />} />
          <Route path="settings" element={<Settings />} />
          <Route path="monitor" element={<Monitor />} /> {/* Add route for Monitor */}
          {/* Add other routes here */}
        </Routes>
      </div>
    </div>
  );
};

export default Dashboard;
