import React, { useState, useEffect, useCallback } from 'react';
import HeaderNav from '../components/Navs/HeaderNav';
import SideNav from '../components/Navs/SideNav';
import MonitorNavTop from '../components/Navs/MonitorNavTop';
import MonitorNavBottom from '../components/Navs/MonitorNavBottom';
import Section from '../components/Section';

import ServerHealthMetricsGroup from '../components/Monitor/ServerHealthMetrics/ServerHealthMetricsGroup';
import ApplicationPerformanceGroup from '../components/Monitor/ApplicationPerformance/ApplicationPerformanceGroup';
import DatabasePerformanceGroup from '../components/Monitor/DatabasePerformance/DatabasePerformanceGroup';
import UserActivityGroup from '../components/Monitor/UserActivity/UserActivityGroup';
import ErrorTrackingGroup from '../components/Monitor/ErrorTracking/ErrorTrackingGroup';
import SecurityMonitoringGroup from '../components/Monitor/SecurityMonitoring/SecurityMonitoringGroup';
import ApplicationMetricsGroup from '../components/Monitor/ApplicationMetrics/ApplicationMetricsGroup';
import ResourceUsageGroup from '../components/Monitor/ResourceUsage/ResourceUsageGroup';
import ServiceHealthGroup from '../components/Monitor/ServiceHealth/ServiceHealthGroup';
import DeploymentMetricsGroup from '../components/Monitor/DeploymentMetrics/DeploymentMetricsGroup';
import RealTimeNotificationsGroup from '../components/Monitor/RealTimeNotifications/RealTimeNotificationsGroup';
import SeoMetricsGroup from '../components/Monitor/SeoMetrics/SeoMetricsGroup';

const Monitor = () => {
  const [activeSection, setActiveSection] = useState('');
  const [clickedSection, setClickedSection] = useState(false);

  const handleSetActiveSection = useCallback((section) => {
    setActiveSection(section);
    setClickedSection(true);
  }, []);

  useEffect(() => {
    if (clickedSection) {
      const timer = setTimeout(() => setClickedSection(false), 2000); // Increase the timeout duration
      return () => clearTimeout(timer);
    }
  }, [clickedSection]);

  return (
    <div className="min-h-screen bg-gray-100 pt-10 flex">
      <HeaderNav />
      <SideNav />
      <div className="flex-1 container mx-auto px-4 py-8">
        <MonitorNavTop setActiveSection={handleSetActiveSection} />
        <MonitorNavBottom setActiveSection={handleSetActiveSection} />
        <h1 className="text-3xl font-bold mb-6">Monitor Dashboard</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <Section name="server-health" activeSection={activeSection} setActiveSection={handleSetActiveSection} clickedSection={clickedSection}>
            <ServerHealthMetricsGroup />
          </Section>
          <Section name="application-performance" activeSection={activeSection} setActiveSection={handleSetActiveSection} clickedSection={clickedSection}>
            <ApplicationPerformanceGroup />
          </Section>
          <Section name="database-performance" activeSection={activeSection} setActiveSection={handleSetActiveSection} clickedSection={clickedSection}>
            <DatabasePerformanceGroup />
          </Section>
          <Section name="user-activity" activeSection={activeSection} setActiveSection={handleSetActiveSection} clickedSection={clickedSection}>
            <UserActivityGroup />
          </Section>
          <Section name="error-tracking" activeSection={activeSection} setActiveSection={handleSetActiveSection} clickedSection={clickedSection}>
            <ErrorTrackingGroup />
          </Section>
          <Section name="security-monitoring" activeSection={activeSection} setActiveSection={handleSetActiveSection} clickedSection={clickedSection}>
            <SecurityMonitoringGroup />
          </Section>
          <Section name="application-metrics" activeSection={activeSection} setActiveSection={handleSetActiveSection} clickedSection={clickedSection}>
            <ApplicationMetricsGroup />
          </Section>
          <Section name="resource-usage" activeSection={activeSection} setActiveSection={handleSetActiveSection} clickedSection={clickedSection}>
            <ResourceUsageGroup />
          </Section>
          <Section name="service-health" activeSection={activeSection} setActiveSection={handleSetActiveSection} clickedSection={clickedSection}>
            <ServiceHealthGroup />
          </Section>
          <Section name="deployment-metrics" activeSection={activeSection} setActiveSection={handleSetActiveSection} clickedSection={clickedSection}>
            <DeploymentMetricsGroup />
          </Section>
          <Section name="real-time-notifications" activeSection={activeSection} setActiveSection={handleSetActiveSection} clickedSection={clickedSection}>
            <RealTimeNotificationsGroup />
          </Section>
          <Section name="seo-metrics" activeSection={activeSection} setActiveSection={handleSetActiveSection} clickedSection={clickedSection}>
            <SeoMetricsGroup />
          </Section>
        </div>
      </div>
    </div>
  );
};

export default Monitor;
