import React, { useState, useEffect } from 'react';
import { fetchVouchers, generateVoucher, updateVoucher, deleteVoucher } from '../components/services/api';
import { FaTrash, FaEdit, FaPlusCircle } from 'react-icons/fa';
import Modal from '../components/Modals/Modal';

const Vouchers = () => {
  const [vouchers, setVouchers] = useState([]);
  const [editVoucher, setEditVoucher] = useState(null);
  const [newCode, setNewCode] = useState('');
  const [newValue, setNewValue] = useState('');
  const [newExpirationDate, setNewExpirationDate] = useState('');
  const [newStatus, setNewStatus] = useState('');
  const [newUserId, setNewUserId] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  useEffect(() => {
    loadVouchers();
  }, []);

  const loadVouchers = async () => {
    setLoading(true);
    try {
      const data = await fetchVouchers();
      setVouchers(data);
    } catch (error) {
      console.error('Error fetching vouchers:', error);
    }
    setLoading(false);
  };

  const handleGenerateVoucher = async () => {
    setLoading(true);
    try {
      const response = await generateVoucher();
      setMessage(`Voucher generated: ${response.voucher_code}`);
      loadVouchers();
    } catch (error) {
      console.error('Error generating voucher:', error);
      setMessage('Error generating voucher');
    }
    setLoading(false);
  };

  const handleUpdateVoucher = async (id) => {
    setLoading(true);
    try {
      await updateVoucher(id, newCode, newValue, newExpirationDate, newStatus, newUserId);
      setEditVoucher(null);
      loadVouchers();
      setMessage('Voucher updated successfully');
      setShowEditModal(false);
    } catch (error) {
      console.error('Error updating voucher:', error);
      setMessage('Error updating voucher');
    }
    setLoading(false);
  };

  const handleDeleteVoucher = async (id) => {
    setLoading(true);
    try {
      await deleteVoucher(id);
      loadVouchers();
      setMessage('Voucher deleted successfully');
    } catch (error) {
      console.error('Error deleting voucher:', error);
      setMessage('Error deleting voucher');
    }
    setLoading(false);
  };

  const closeModal = () => {
    setShowEditModal(false);
    setEditVoucher(null);
    setNewCode('');
    setNewValue('');
    setNewExpirationDate('');
    setNewStatus('');
    setNewUserId('');
  };

  return (
    <div className="min-h-screen bg-gray-100 p-4">
      <h1 className="text-2xl font-bold mb-4">Vouchers</h1>
      <button className="bg-blue-500 text-white px-4 py-2 rounded mb-4 flex items-center shadow-lg hover:bg-blue-600 transition duration-200" onClick={handleGenerateVoucher}>
        <FaPlusCircle className="mr-2" /> Generate Voucher
      </button>
      {message && <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4" role="alert">{message}</div>}
      {loading ? (
        <div className="text-center py-6">Loading...</div>
      ) : (
        <table className="w-full bg-white rounded-lg shadow-lg">
          <thead>
            <tr className="bg-gray-200">
              <th className="border-b p-2 text-left">Code</th>
              <th className="border-b p-2 text-left">Value</th>
              <th className="border-b p-2 text-left">Expiration Date</th>
              <th className="border-b p-2 text-left">Status</th>
              <th className="border-b p-2 text-left">User ID</th>
              <th className="border-b p-2 text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            {vouchers.map((voucher) => (
              <tr key={voucher.id} className="hover:bg-gray-100 transition duration-200">
                <td className="border px-4 py-2">{voucher.code}</td>
                <td className="border px-4 py-2">{voucher.value}</td>
                <td className="border px-4 py-2">{voucher.expiration_date}</td>
                <td className="border px-4 py-2">{voucher.status}</td>
                <td className="border px-4 py-2">{voucher.user_id}</td>
                <td className="border px-4 py-2 flex space-x-2">
                  <button
                    className="bg-yellow-500 text-white px-2 py-1 rounded shadow-lg hover:bg-yellow-600 transition duration-200"
                    onClick={() => {
                      setEditVoucher(voucher);
                      setNewCode(voucher.code);
                      setNewValue(voucher.value);
                      setNewExpirationDate(voucher.expiration_date);
                      setNewStatus(voucher.status);
                      setNewUserId(voucher.user_id);
                      setShowEditModal(true);
                    }}
                  >
                    <FaEdit />
                  </button>
                  <button
                    className="bg-red-500 text-white px-2 py-1 rounded shadow-lg hover:bg-red-600 transition duration-200"
                    onClick={() => handleDeleteVoucher(voucher.id)}
                  >
                    <FaTrash />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {showEditModal && (
        <Modal title="Edit Voucher" onClose={closeModal}>
          <input
            type="text"
            className="w-full p-2 border rounded mb-2"
            placeholder="Code"
            value={newCode}
            onChange={(e) => setNewCode(e.target.value)}
          />
          <input
            type="text"
            className="w-full p-2 border rounded mb-2"
            placeholder="Value"
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
          />
          <input
            type="date"
            className="w-full p-2 border rounded mb-2"
            placeholder="Expiration Date"
            value={newExpirationDate}
            onChange={(e) => setNewExpirationDate(e.target.value)}
          />
          <input
            type="text"
            className="w-full p-2 border rounded mb-2"
            placeholder="Status"
            value={newStatus}
            onChange={(e) => setNewStatus(e.target.value)}
          />
          <input
            type="text"
            className="w-full p-2 border rounded mb-2"
            placeholder="User ID"
            value={newUserId}
            onChange={(e) => setNewUserId(e.target.value)}
          />
          <button
            className="bg-green-500 text-white px-4 py-2 rounded shadow-lg hover:bg-green-600 transition duration-200"
            onClick={() => handleUpdateVoucher(editVoucher.id)}
          >
            Update Voucher
          </button>
        </Modal>
      )}
    </div>
  );
};

export default Vouchers;
