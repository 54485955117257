import React, { useState } from 'react';
import { updateUserBalance } from '../services/api';
import { FaTimes, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import './modal.css';
import ErrorModal from './ErrorModal';
import SuccessModal from './SuccessModal';

const UserUpdateBalanceModal = ({ isOpen, onClose, userId, currentBalance }) => {
  const [newBalance, setNewBalance] = useState(currentBalance);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await updateUserBalance(userId, parseFloat(newBalance));
      
      if (response.status === 'success') {
        setSuccessMessage('Balance updated successfully');
        setIsSuccessModalOpen(true);
      } else {
        setErrorMessage('Failed to update balance');
        setIsErrorModalOpen(true);
      }
    } catch (error) {
      console.error('Error updating balance:', error);
      setErrorMessage('Failed to update balance');
      setIsErrorModalOpen(true);
    }
  };

  const handleClose = () => {
    setIsSuccessModalOpen(false);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="modal-overlay">
        <div className="modal-content">
          <div className="modal-header">
            <h2>Update Balance</h2>
            <button onClick={onClose}><FaTimes /></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="mb-6">
                <label htmlFor="newBalance">New Balance</label>
                <input
                  type="number"
                  id="newBalance"
                  className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-500"
                  value={newBalance}
                  onChange={(e) => setNewBalance(e.target.value)}
                  min="0"
                  step="0.01"
                />
              </div>
              <div className="modal-footer">
                <button type="submit" className="submit flex items-center"><FaCheckCircle className="mr-2" /> Update</button>
                <button type="button" className="cancel flex items-center" onClick={onClose}><FaTimesCircle className="mr-2" /> Cancel</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ErrorModal isOpen={isErrorModalOpen} onClose={() => setIsErrorModalOpen(false)} errorMessage={errorMessage} />
      <SuccessModal isOpen={isSuccessModalOpen} onClose={handleClose} message={successMessage} />
    </>
  );
};

export default UserUpdateBalanceModal;
