import axios from 'axios';

const API_URL = 'https://homeaidrelief.com/api';

const createApiUrl = (endpoint) => `${API_URL}/${endpoint}.php`;

const getToken = () => {
  return localStorage.getItem('token');
};

const notificationApi = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

notificationApi.interceptors.request.use(config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

// Fetch admin notifications
export const fetchAdminNotifications = async () => {
  try {
    const response = await notificationApi.get(createApiUrl('Notifications/getAdminNotifications'));
    return response.data;
  } catch (error) {
    console.error('Error fetching admin notifications:', error);
    throw error;
  }
};

// Create admin notification
export const createAdminNotification = async (message, userId) => {
  try {
    const response = await notificationApi.post(createApiUrl('Notifications/createAdminNotification'), { message, userId });
    return response.data;
  } catch (error) {
    console.error('Error creating admin notification:', error);
    throw error;
  }
};

// Mark admin notification as read
export const markAdminNotificationAsRead = async (payload) => {
  try {
    const response = await notificationApi.post(createApiUrl('Notifications/markAdminNotificationAsRead'), payload);
    return response.data;
  } catch (error) {
    console.error('Error marking admin notification as read:', error);
    throw error;
  }
};
