import { useState, useEffect, useRef } from 'react';
import { fetchAdminNotifications, markAdminNotificationAsRead } from '../components/services/notificationCall';

const useNotifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [isAudioAllowed, setIsAudioAllowed] = useState(false);
  const [previousUnreadCount, setPreviousUnreadCount] = useState(0);
  const audioRef = useRef(new Audio('/ding.mp3'));

  useEffect(() => {
    const handleUserInteraction = () => {
      setIsAudioAllowed(true);
      window.removeEventListener('click', handleUserInteraction);
      window.removeEventListener('keydown', handleUserInteraction);
    };

    window.addEventListener('click', handleUserInteraction);
    window.addEventListener('keydown', handleUserInteraction);

    return () => {
      window.removeEventListener('click', handleUserInteraction);
      window.removeEventListener('keydown', handleUserInteraction);
    };
  }, []);

  useEffect(() => {
    const fetchNotificationData = async () => {
      try {
        const response = await fetchAdminNotifications();

        const sortedNotifications = response.notifications.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        setNotifications(sortedNotifications);

        const unread = sortedNotifications.filter(notification => Number(notification.is_read) === 0).length;

        if (isAudioAllowed && unread > previousUnreadCount) {
          audioRef.current.play().catch(error => console.error('Error playing audio:', error));
        }

        setPreviousUnreadCount(unread);
        setUnreadCount(unread);
      } catch (error) {
        // Suppress CORS error logging
        if (error.code !== 'ERR_NETWORK' && !error.message.includes('CORS')) {
          console.error('Error fetching notifications:', error);
        }
      }
    };

    fetchNotificationData();
    const intervalId = setInterval(fetchNotificationData, 5000); // Fetch notifications every 5 seconds

    return () => clearInterval(intervalId);
  }, [isAudioAllowed, previousUnreadCount]);

  const markAsRead = async (notificationId) => {
    try {
      const response = await markAdminNotificationAsRead({ notification_id: notificationId });

      if (response.success) {
        setNotifications(notifications.map(notification =>
          notification.id === notificationId ? { ...notification, is_read: 1 } : notification
        ));
        setUnreadCount(prevCount => prevCount - 1);
      } else {
        console.error('Error marking notification as read:', response.error);
      }
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  return {
    notifications,
    unreadCount,
    markAsRead,
  };
};

export default useNotifications;
