// src/components/Modals/IframeModal.js
import React, { useState } from 'react';
import './IframeModal.css';

const IframeModal = ({ show, onClose, url }) => {
  const [isMinimized, setIsMinimized] = useState(false);

  if (!show) {
    return null;
  }

  const handleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  return (
    <div className={`iframe-modal-overlay ${isMinimized ? 'minimized' : ''}`}>
      <div className={`iframe-modal ${isMinimized ? 'minimized' : ''}`}>
        <div className="iframe-modal-header">
          <button onClick={handleMinimize} className="iframe-modal-button">
            {isMinimized ? '🗖' : '🗕'}
          </button>
          <button onClick={onClose} className="iframe-modal-button">✖</button>
        </div>
        {!isMinimized && (
          <iframe src={url} title="Iframe Modal" className="iframe-content"></iframe>
        )}
      </div>
    </div>
  );
};

export default IframeModal;
