import React, { useState, useEffect } from 'react';
import { getSeoSettings, saveSeoSettings, uploadImage } from '../services/seoApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaFileImage, FaSave, FaTimes, FaTags, FaTwitter, FaFacebookF, FaCode, FaInfoCircle, FaLink } from 'react-icons/fa';
import Tooltip from '@mui/material/Tooltip';
import './SEOModal.css';

const SEOModal = ({ onClose, pageUrl }) => {
  const [seoData, setSeoData] = useState({
    metaTitle: '',
    metaDescription: '',
    metaKeywords: '',
    ogTitle: '',
    ogDescription: '',
    ogImage: '',
    ogImageAlt: '',
    twitterTitle: '',
    twitterDescription: '',
    twitterImage: '',
    twitterImageAlt: '',
    jsonLd: '',
    canonicalUrl: '',
    robotsContent: ''
  });

  const [selectedSchema, setSelectedSchema] = useState('Article');
  const schemaTypes = ['Article', 'Product', 'Event', 'Recipe'];

  useEffect(() => {
    const fetchSeoData = async () => {
      try {
        const response = await getSeoSettings(pageUrl);
        setSeoData({
          metaTitle: response.data.meta_title || '',
          metaDescription: response.data.meta_description || '',
          metaKeywords: response.data.meta_keywords || '',
          ogTitle: response.data.og_title || '',
          ogDescription: response.data.og_description || '',
          ogImage: response.data.og_image || '',
          ogImageAlt: response.data.og_image_alt || '',
          twitterTitle: response.data.twitter_title || '',
          twitterDescription: response.data.twitter_description || '',
          twitterImage: response.data.twitter_image || '',
          twitterImageAlt: response.data.twitter_image_alt || '',
          jsonLd: response.data.json_ld || '',
          canonicalUrl: response.data.canonical_url || '',
          robotsContent: response.data.robots_content || ''
        });
      } catch (error) {
        console.error('Error fetching SEO data:', error);
      }
    };

    fetchSeoData();
  }, [pageUrl]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSeoData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleImageUpload = (e) => {
    const { name } = e.target;
    const formData = new FormData();
    formData.append('image', e.target.files[0]);

    uploadImage(formData)
      .then(response => {
        setSeoData(prevState => ({
          ...prevState,
          [name]: response.data.imageUrl
        }));
        toast.success('Image uploaded successfully');
      })
      .catch(error => {
        console.error('Error uploading image:', error);
        toast.error('Failed to upload image');
      });
  };

  const handleSave = async () => {
    try {
      const response = await saveSeoSettings({ ...seoData, pageUrl });
      if (response.success) {
        toast.success('SEO settings saved successfully');
        setTimeout(() => {
          onClose();
        }, 2000); // Delay closing the modal to allow the toast notification to show
      } else {
        toast.error('Failed to save SEO settings: ' + response.error);
      }
    } catch (error) {
      console.error('Error saving SEO data:', error);
      toast.error('Failed to save SEO settings');
    }
  };

  const loadCurrentSEO = () => {
    getSeoSettings(pageUrl)
      .then(response => {
        setSeoData({
          metaTitle: response.data.meta_title || '',
          metaDescription: response.data.meta_description || '',
          metaKeywords: response.data.meta_keywords || '',
          ogTitle: response.data.og_title || '',
          ogDescription: response.data.og_description || '',
          ogImage: response.data.og_image || '',
          ogImageAlt: response.data.og_image_alt || '',
          twitterTitle: response.data.twitter_title || '',
          twitterDescription: response.data.twitter_description || '',
          twitterImage: response.data.twitter_image || '',
          twitterImageAlt: response.data.twitter_image_alt || '',
          jsonLd: response.data.json_ld || '',
          canonicalUrl: response.data.canonical_url || '',
          robotsContent: response.data.robots_content || ''
        });
      })
      .catch(error => console.error('Error fetching SEO data:', error));
  };

  return (
    <div className="modal-backdrop">
      <div className="modal-full">
        <div className="modal-header">
          <h2>SEO Settings</h2>
          <button onClick={onClose} className="close-button">
            <FaTimes />
          </button>
        </div>
        <div className="modal-body">
          <div className="modal-section">
            <section>
              <h3>
                <FaTags className="section-icon" /> Meta Tags
                <Tooltip title="Meta tags provide information about the contents of your page to search engines and website visitors." arrow>
                  <span><FaInfoCircle className="info-icon" /></span>
                </Tooltip>
              </h3>
              <div className="form-group">
                <label htmlFor="metaTitle">Meta Title</label>
                <input
                  type="text"
                  id="metaTitle"
                  name="metaTitle"
                  value={seoData.metaTitle}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label htmlFor="metaDescription">Meta Description</label>
                <textarea
                  id="metaDescription"
                  name="metaDescription"
                  value={seoData.metaDescription}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label htmlFor="metaKeywords">Meta Keywords</label>
                <input
                  type="text"
                  id="metaKeywords"
                  name="metaKeywords"
                  value={seoData.metaKeywords}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </section>
            <section>
              <h3>
                <FaFacebookF className="section-icon" /> Open Graph Tags
                <Tooltip title="Open Graph tags are used to control how your website is displayed when shared on social media platforms." arrow>
                  <span><FaInfoCircle className="info-icon" /></span>
                </Tooltip>
              </h3>
              <div className="form-group">
                <label htmlFor="ogTitle">Open Graph Title</label>
                <input
                  type="text"
                  id="ogTitle"
                  name="ogTitle"
                  value={seoData.ogTitle}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label htmlFor="ogDescription">Open Graph Description</label>
                <textarea
                  id="ogDescription"
                  name="ogDescription"
                  value={seoData.ogDescription}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label htmlFor="ogImage">Open Graph Image</label>
                <input
                  type="file"
                  id="ogImage"
                  name="ogImage"
                  onChange={handleImageUpload}
                  className="form-control"
                />
                {seoData.ogImage && <img src={seoData.ogImage} alt="Open Graph" className="preview-image" />}
              </div>
              <div className="form-group">
                <label htmlFor="ogImageAlt">Open Graph Image Alt Text</label>
                <input
                  type="text"
                  id="ogImageAlt"
                  name="ogImageAlt"
                  value={seoData.ogImageAlt}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </section>
          </div>
          <div className="modal-section">
            <section>
              <h3>
                <FaTwitter className="section-icon" /> Twitter Card Tags
                <Tooltip title="Twitter Card tags allow you to control how your content appears when shared on Twitter." arrow>
                  <span><FaInfoCircle className="info-icon" /></span>
                </Tooltip>
              </h3>
              <div className="form-group">
                <label htmlFor="twitterTitle">Twitter Title</label>
                <input
                  type="text"
                  id="twitterTitle"
                  name="twitterTitle"
                  value={seoData.twitterTitle}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label htmlFor="twitterDescription">Twitter Description</label>
                <textarea
                  id="twitterDescription"
                  name="twitterDescription"
                  value={seoData.twitterDescription}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label htmlFor="twitterImage">Twitter Image</label>
                <input
                  type="file"
                  id="twitterImage"
                  name="twitterImage"
                  onChange={handleImageUpload}
                  className="form-control"
                />
                {seoData.twitterImage && <img src={seoData.twitterImage} alt="Twitter" className="preview-image" />}
              </div>
              <div className="form-group">
                <label htmlFor="twitterImageAlt">Twitter Image Alt Text</label>
                <input
                  type="text"
                  id="twitterImageAlt"
                  name="twitterImageAlt"
                  value={seoData.twitterImageAlt}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </section>
            <section>
              <h3>
                <FaCode className="section-icon" /> Structured Data (JSON-LD)
                <Tooltip title="Structured Data in JSON-LD format helps search engines understand the content and structure of your site." arrow>
                  <span><FaInfoCircle className="info-icon" /></span>
                </Tooltip>
              </h3>
              <div className="form-group">
                <label htmlFor="schemaType">Schema Type</label>
                <select
                  id="schemaType"
                  name="schemaType"
                  value={selectedSchema}
                  onChange={(e) => setSelectedSchema(e.target.value)}
                  className="form-control"
                >
                  {schemaTypes.map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="jsonLd">Structured Data (JSON-LD)</label>
                <textarea
                  id="jsonLd"
                  name="jsonLd"
                  value={seoData.jsonLd}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </section>
            <section>
              <h3>
                <FaLink className="section-icon" /> Canonical Tags
                <Tooltip title="Canonical tags help search engines understand which version of a URL should be considered the main version." arrow>
                  <span><FaInfoCircle className="info-icon" /></span>
                </Tooltip>
              </h3>
              <div className="form-group">
                <label htmlFor="canonicalUrl">Canonical URL</label>
                <input
                  type="text"
                  id="canonicalUrl"
                  name="canonicalUrl"
                  value={seoData.canonicalUrl}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </section>
            <section>
              <h3>
                <FaInfoCircle className="section-icon" /> Robots Meta Tag
                <Tooltip title="The robots meta tag tells search engines which pages they can crawl and index." arrow>
                  <span><FaInfoCircle className="info-icon" /></span>
                </Tooltip>
              </h3>
              <div className="form-group">
                <label htmlFor="robotsContent">Robots Content</label>
                <input
                  type="text"
                  id="robotsContent"
                  name="robotsContent"
                  value={seoData.robotsContent}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </section>
          </div>
        </div>
        <div className="modal-footer">
          <button onClick={loadCurrentSEO} className="btn btn-secondary">
            Use Current SEO
          </button>
          <button onClick={handleSave} className="btn btn-primary">
            <FaSave /> Save
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default SEOModal;
