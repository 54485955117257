import React, { useState } from 'react';
import { Bar, Line, Pie } from 'react-chartjs-2';
import { FaTools } from 'react-icons/fa';
import { barChartData, barChartOptions, lineChartData, lineChartOptions, pieChartData, pieChartOptions } from '../chartConfig';
import './ResourceUsageGroup.css';

const sections = [
  { id: 'resource-allocation', label: 'Resource Allocation', icon: FaTools },
  { id: 'resource-limits-quotas', label: 'Resource Limits and Quotas', icon: FaTools },
  { id: 'auto-scaling-events', label: 'Auto-scaling Events', icon: FaTools }
];

const ResourceUsageGroup = () => {
  const [activeSection, setActiveSection] = useState(sections[0].id);

  const renderContent = () => {
    switch (activeSection) {
      case 'resource-allocation':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center">
              <FaTools className="mr-2" /> Resource Allocation
            </h2>
            <Bar data={barChartData} options={barChartOptions} />
          </div>
        );
      case 'resource-limits-quotas':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center">
              <FaTools className="mr-2" /> Resource Limits and Quotas
            </h2>
            <Line data={lineChartData} options={lineChartOptions} />
          </div>
        );
      case 'auto-scaling-events':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center">
              <FaTools className="mr-2" /> Auto-scaling Events
            </h2>
            <Pie data={pieChartData} options={pieChartOptions} />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="resource-usage-group bg-white shadow-lg rounded-lg p-6">
      <h2 className="text-2xl font-bold flex items-center mb-4">
        <FaTools className="mr-2" /> Resource Usage Dashboard
      </h2>
      <div className="navigation mb-4">
        {sections.map((section) => (
          <button
            key={section.id}
            className={`nav-button ${activeSection === section.id ? 'active' : ''}`}
            onClick={() => setActiveSection(section.id)}
          >
            <section.icon className="mr-2" />
            {section.label}
          </button>
        ))}
      </div>
      <div className="content">{renderContent()}</div>
    </div>
  );
};

export default ResourceUsageGroup;
