import React, { useState, useRef, useEffect } from 'react';
import { FaTimes, FaMicrochip, FaClock, FaDatabase, FaUsers, FaBug, FaLock, FaHeartbeat, FaChartLine, FaTools, FaBell, FaSearch } from 'react-icons/fa';
import ServerHealthMetricsGroup from '../Monitor/ServerHealthMetrics/ServerHealthMetricsGroup';
import ApplicationPerformanceGroup from '../Monitor/ApplicationPerformance/ApplicationPerformanceGroup';
import DatabasePerformanceGroup from '../Monitor/DatabasePerformance/DatabasePerformanceGroup';
import UserActivityGroup from '../Monitor/UserActivity/UserActivityGroup';
import ErrorTrackingGroup from '../Monitor/ErrorTracking/ErrorTrackingGroup';
import SecurityMonitoringGroup from '../Monitor/SecurityMonitoring/SecurityMonitoringGroup';
import ApplicationMetricsGroup from '../Monitor/ApplicationMetrics/ApplicationMetricsGroup';
import ResourceUsageGroup from '../Monitor/ResourceUsage/ResourceUsageGroup';
import ServiceHealthGroup from '../Monitor/ServiceHealth/ServiceHealthGroup';
import DeploymentMetricsGroup from '../Monitor/DeploymentMetrics/DeploymentMetricsGroup';
import RealTimeNotificationsGroup from '../Monitor/RealTimeNotifications/RealTimeNotificationsGroup';
import SeoMetricsGroup from '../Monitor/SeoMetrics/SeoMetricsGroup';
import './MonitorModal.css';

const MonitorModal = ({ onClose }) => {
  const [activeGroup, setActiveGroup] = useState(null);
  const modalRef = useRef(null);
  const triggerButtonRef = useRef(null);

  const renderGroup = () => {
    switch (activeGroup) {
      case 'server-health':
        return <ServerHealthMetricsGroup />;
      case 'application-performance':
        return <ApplicationPerformanceGroup />;
      case 'database-performance':
        return <DatabasePerformanceGroup />;
      case 'user-activity':
        return <UserActivityGroup />;
      case 'error-tracking':
        return <ErrorTrackingGroup />;
      case 'security-monitoring':
        return <SecurityMonitoringGroup />;
      case 'application-metrics':
        return <ApplicationMetricsGroup />;
      case 'resource-usage':
        return <ResourceUsageGroup />;
      case 'service-health':
        return <ServiceHealthGroup />;
      case 'deployment-metrics':
        return <DeploymentMetricsGroup />;
      case 'real-time-notifications':
        return <RealTimeNotificationsGroup />;
      case 'seo-metrics':
        return <SeoMetricsGroup />;
      default:
        return null;
    }
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.focus();
    }
    return () => {
      if (triggerButtonRef.current) {
        triggerButtonRef.current.focus();
      }
    };
  }, []);

  return (
    <div className="monitor-modal" role="dialog" aria-modal="true" aria-labelledby="modal-title">
      <div className="monitor-modal-content" ref={modalRef} tabIndex="-1">
        <button className="close-button" onClick={onClose} aria-label="Close Modal">
          <FaTimes />
        </button>
        <h2 className="modal-title" id="modal-title">Monitor Dashboard</h2>
        {activeGroup ? (
          <div className="group-content">
            <button onClick={() => setActiveGroup(null)} className="back-button">
              Back to Overview
            </button>
            {renderGroup()}
          </div>
        ) : (
          <div className="cards-container">
            <div className="card" onClick={() => setActiveGroup('server-health')}>
              <FaMicrochip className="card-icon" style={{ color: '#FF6347' }} />
              <h3>Server Health</h3>
              <p>Monitor the status and performance of your servers.</p>
            </div>
            <div className="card" onClick={() => setActiveGroup('application-performance')}>
              <FaClock className="card-icon" style={{ color: '#4682B4' }} />
              <h3>App Performance</h3>
              <p>Analyze the performance metrics of your applications.</p>
            </div>
            <div className="card" onClick={() => setActiveGroup('database-performance')}>
              <FaDatabase className="card-icon" style={{ color: '#6A5ACD' }} />
              <h3>Database Performance</h3>
              <p>Track the efficiency and speed of your databases.</p>
            </div>
            <div className="card" onClick={() => setActiveGroup('user-activity')}>
              <FaUsers className="card-icon" style={{ color: '#32CD32' }} />
              <h3>User Activity</h3>
              <p>Monitor the activity and engagement of your users.</p>
            </div>
            <div className="card" onClick={() => setActiveGroup('error-tracking')}>
              <FaBug className="card-icon" style={{ color: '#FF4500' }} />
              <h3>Error Tracking</h3>
              <p>Identify and manage errors and bugs in your applications.</p>
            </div>
            <div className="card" onClick={() => setActiveGroup('security-monitoring')}>
              <FaLock className="card-icon" style={{ color: '#2E8B57' }} />
              <h3>Security Monitoring</h3>
              <p>Ensure the security and integrity of your systems.</p>
            </div>
            <div className="card" onClick={() => setActiveGroup('application-metrics')}>
              <FaChartLine className="card-icon" style={{ color: '#DAA520' }} />
              <h3>App Metrics</h3>
              <p>Review key metrics for your applications.</p>
            </div>
            <div className="card" onClick={() => setActiveGroup('resource-usage')}>
              <FaTools className="card-icon" style={{ color: '#A52A2A' }} />
              <h3>Resource Usage</h3>
              <p>Monitor the utilization of your resources.</p>
            </div>
            <div className="card" onClick={() => setActiveGroup('service-health')}>
              <FaHeartbeat className="card-icon" style={{ color: '#4682B4' }} />
              <h3>Service Health</h3>
              <p>Check the health status of your services.</p>
            </div>
            <div className="card" onClick={() => setActiveGroup('deployment-metrics')}>
              <FaTools className="card-icon" style={{ color: '#8B0000' }} />
              <h3>Deployment Metrics</h3>
              <p>Analyze the metrics of your deployments.</p>
            </div>
            <div className="card" onClick={() => setActiveGroup('real-time-notifications')}>
              <FaBell className="card-icon" style={{ color: '#FF8C00' }} />
              <h3>Real-time Notifications</h3>
              <p>Receive instant notifications in real-time.</p>
            </div>
            <div className="card" onClick={() => setActiveGroup('seo-metrics')}>
              <FaSearch className="card-icon" style={{ color: '#FF69B4' }} />
              <h3>SEO Metrics</h3>
              <p>Track the SEO performance of your website.</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MonitorModal;
