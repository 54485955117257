import React, { useEffect, useState } from 'react';
import { FaUsers, FaMoneyBillWave, FaTicketAlt, FaHandHoldingUsd } from 'react-icons/fa';
import { motion } from 'framer-motion';
import UserList from '../components/User/UserList';
import { fetchUsersCount, fetchTopUpRequestsCount, fetchTicketsPlayedCount, fetchWithdrawRequestsCount, fetchAllUsers, updateAllUser } from '../components/services/api';
import UserListModal from '../components/Modals/UserListModal';
import EditUserModal from '../components/Modals/EditUserModal';
import Seo from '../components/Seo';

const Home = () => {
  const [data, setData] = useState({
    users: 0,
    topUpRequests: 0,
    ticketsPlayed: 0,
    withdrawRequests: 0,
  });
  const [isUserListModalOpen, setIsUserListModalOpen] = useState(false);
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersCount = await fetchUsersCount();
        const topUpRequestsCount = await fetchTopUpRequestsCount();
        const ticketsPlayedCount = await fetchTicketsPlayedCount();
        const withdrawRequestsCount = await fetchWithdrawRequestsCount();

        setData({
          users: usersCount.count || 0,
          topUpRequests: topUpRequestsCount.count || 0,
          ticketsPlayed: ticketsPlayedCount.count || 0,
          withdrawRequests: withdrawRequestsCount.count || 0,
        });
      } catch (error) {
        console.error('Error fetching data:', error.response?.data || error.message);
      }
    };

    fetchData();

    const interval = setInterval(fetchData, 100); // Refresh every 60 seconds
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  const handleUsersCardClick = async () => {
    try {
      const response = await fetchAllUsers();
      if (response.status === 'success') {
        setUsers(response.users);
        setIsUserListModalOpen(true);
      } else {
        alert('Failed to fetch users');
      }
    } catch (error) {
      console.error('Error in handleUsersCardClick:', error.response?.data || error.message);
      alert('Failed to fetch users');
    }
  };

  const handleSelectUser = (user) => {
    setSelectedUser(user);
    setIsUserListModalOpen(false);
    setIsEditUserModalOpen(true);
  };

  const handleUpdateUser = async (updatedUser) => {
    try {
      const response = await updateAllUser(updatedUser);
      if (response.status === 'success') {
        setUsers(users.map(user => (user.id === updatedUser.id ? updatedUser : user)));
      } else {
        alert('Failed to update user');
      }
    } catch (error) {
      console.error('Error updating user:', error.response?.data || error.message);
      alert('Failed to update user');
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 pt-4 px-4">
      <Seo pageUrl={window.location.pathname} />
      <div className="container mx-auto py-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          <div
            className="bg-white rounded-lg shadow-lg p-4 flex flex-col items-center justify-center transition-transform transform hover:scale-105 cursor-pointer"
            onClick={handleUsersCardClick}
          >
            <FaUsers className="text-4xl text-blue-500 mb-2" />
            <h2 className="text-lg font-bold">Users</h2>
            <motion.p
              className="text-2xl mt-2"
              animate={{ opacity: [0, 1, 0] }}
              transition={{ duration: 1, repeat: Infinity }}
            >
              {data.users}
            </motion.p>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-4 flex flex-col items-center justify-center transition-transform transform hover:scale-105">
            <FaMoneyBillWave className="text-4xl text-green-500 mb-2" />
            <h2 className="text-lg font-bold">Top Up Requests</h2>
            <motion.p
              className="text-2xl mt-2"
              animate={{ opacity: [0, 1, 0] }}
              transition={{ duration: 1, repeat: Infinity }}
            >
              {data.topUpRequests}
            </motion.p>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-4 flex flex-col items-center justify-center transition-transform transform hover:scale-105">
            <FaTicketAlt className="text-4xl text-yellow-500 mb-2" />
            <h2 className="text-lg font-bold">Tickets Played</h2>
            <motion.p
              className="text-2xl mt-2"
              animate={{ opacity: [0, 1, 0] }}
              transition={{ duration: 1, repeat: Infinity }}
            >
              {data.ticketsPlayed}
            </motion.p>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-4 flex flex-col items-center justify-center transition-transform transform hover:scale-105">
            <FaHandHoldingUsd className="text-4xl text-red-500 mb-2" />
            <h2 className="text-lg font-bold">Withdraw Requests</h2>
            <motion.p
              className="text-2xl mt-2"
              animate={{ opacity: [0, 1, 0] }}
              transition={{ duration: 1, repeat: Infinity }}
            >
              {data.withdrawRequests}
            </motion.p>
          </div>
        </div>
        <UserList onSelectUser={handleSelectUser} />
        <UserListModal
          isOpen={isUserListModalOpen}
          onClose={() => setIsUserListModalOpen(false)}
          users={users}
          onSelectUser={handleSelectUser}
        />
        <EditUserModal
          isOpen={isEditUserModalOpen}
          onClose={() => setIsEditUserModalOpen(false)}
          user={selectedUser}
          onUpdateUser={handleUpdateUser}
        />
      </div>
    </div>
  );
};

export default Home;
