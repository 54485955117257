// src/components/Modals/UserListModal.js
import React from 'react';
import { FaUser, FaTimes, FaChevronLeft } from 'react-icons/fa';
import './ModalUser.css';
import './modal.css';

const UserListModal = ({ isOpen, onClose, users, onSelectUser, onBack }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-container" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <button className="modal-back" onClick={onBack}>
            <FaChevronLeft />
          </button>
          <h2 className="modal-title">Users List</h2>
          <button className="modal-close" onClick={onClose}>
            <FaTimes />
          </button>
        </div>
        <div className="user-list">
          {users.map(user => (
            <div key={user.id} className="user-item" onClick={() => onSelectUser(user)}>
              <FaUser className="user-icon" />
              <p>{user.first_name} {user.last_name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UserListModal;
