import React, { useState, useRef, useEffect } from 'react';
import Modal from 'react-modal';
import { FaTimes, FaDatabase } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setupDatabase } from '../services/ServerApi';
import { useNavigate } from 'react-router-dom';
import './SetupDatabaseModal.css';

Modal.setAppElement('#root'); // Adjust this as needed

const SetupDatabaseModal = ({ isOpen, onRequestClose }) => {
  const navigate = useNavigate();
  const closeButtonRef = useRef(null);
  const [newDbDetails, setNewDbDetails] = useState({
    dbHost: 'localhost',
    dbUser: '',
    dbPass: '',
    dbName: ''
  });
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewDbDetails({ ...newDbDetails, [name]: value });
  };

  const handleSetupDatabase = async () => {
    if (!newDbDetails.dbHost || !newDbDetails.dbUser || !newDbDetails.dbPass || !newDbDetails.dbName) {
      toast.error('All fields are required.', { autoClose: 3000 });
      return;
    }

    toast.info('Starting database setup...', { autoClose: 3000 });
    setLoading(true);
    try {
      const result = await setupDatabase(newDbDetails);
      setLoading(false);
      if (result.success) {
        toast.success('Database setup successfully!', {
          autoClose: 3000,
          onClose: () => {
            if (closeButtonRef.current) {
              closeButtonRef.current.click();
            }
          }
        });
      } else {
        toast.error(result.message, { autoClose: 3000 });
      }
    } catch (error) {
      setLoading(false);
      toast.error(`Error setting up database: ${error.message}`, { autoClose: 3000 });
    }
  };

  useEffect(() => {
    if (closeButtonRef.current) {
      closeButtonRef.current.onclick = () => navigate('/login');
    }
  }, [navigate]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <div className="modal-overlay-section2">
          <div className="modal-container-section2">
            <div className="modal-header-section2">
              <h2><FaDatabase /> Database Setup</h2>
              <button className="close-button-section2" onClick={onRequestClose}>
                <FaTimes />
              </button>
            </div>
            <div className="modal-content-section2">
              <div className="box-section2">
                <h3>Setup New Database</h3>
                <p>Please ensure the DB Host is set to "localhost".</p>
                <div className="form-group">
                  <input
                    type="text"
                    name="dbHost"
                    placeholder="DB Host"
                    value={newDbDetails.dbHost}
                    onChange={handleInputChange}
                    className="form-input"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="dbUser"
                    placeholder="DB User"
                    value={newDbDetails.dbUser}
                    onChange={handleInputChange}
                    className="form-input"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    name="dbPass"
                    placeholder="DB Pass"
                    value={newDbDetails.dbPass}
                    onChange={handleInputChange}
                    className="form-input"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="dbName"
                    placeholder="DB Name"
                    value={newDbDetails.dbName}
                    onChange={handleInputChange}
                    className="form-input"
                  />
                </div>
                <motion.button
                  onClick={handleSetupDatabase}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  disabled={loading}
                >
                  {loading ? 'Setting Up...' : 'Setup Database'}
                </motion.button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <button
        ref={closeButtonRef}
        style={{ display: 'none' }}
        onClick={() => navigate('/login')}
      >
        Close and Go to Login
      </button>
      <ToastContainer />
    </>
  );
};

export default SetupDatabaseModal;
