// src/components/Navs/SideNav.js
import React, { useState } from 'react';
import { FaHome, FaCog, FaSignOutAlt, FaBullhorn, FaEnvelope, FaNewspaper, FaTicketAlt, FaPaperPlane } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { Link, useNavigate } from 'react-router-dom';
import LogoutModal from '../Modals/LogoutModal';
import IframeModal from '../Modals/IframeModal';

const menuItems = [
  { icon: <FaHome />, text: 'Home', path: '/dashboard/home' },
  { icon: <FaBullhorn />, text: 'Announcements', path: '/dashboard/announcements' },
  { icon: <FaEnvelope />, text: 'Messages', path: '/dashboard/messages' },
  { icon: <FaNewspaper />, text: 'News', path: '/dashboard/news' },
  { icon: <FaTicketAlt />, text: 'Vouchers', path: '/dashboard/vouchers' },
  { icon: <FaCog />, text: 'Settings', path: '/dashboard/settings' },
  { icon: <FaPaperPlane />, text: 'Email Sender', url: 'https://sender.homeaidrelief.com' }, // Use 'url' instead of 'path' for external link
];

const SideNav = ({ isOpen }) => {
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [isIframeModalOpen, setIsIframeModalOpen] = useState(false);
  const [iframeUrl, setIframeUrl] = useState('');
  const navigate = useNavigate();

  const handleLogout = () => {
    setIsLogoutModalOpen(true);
  };

  const confirmLogout = () => {
    // Perform the logout logic here (e.g., clear auth tokens)
    setIsLogoutModalOpen(false);
    navigate('/login'); // Redirect to login page after logout
  };

  const handleMenuItemClick = (item) => {
    if (item.url) {
      setIframeUrl(item.url);
      setIsIframeModalOpen(true);
    } else {
      navigate(item.path);
    }
  };

  return (
    <>
      <motion.div
        initial={{ width: isOpen ? 200 : 60 }}
        animate={{ width: isOpen ? 200 : 60 }}
        className="fixed top-16 left-0 h-full z-40 flex flex-col items-center"
        style={{ backgroundColor: 'transparent' }}
      >
        {menuItems.map((item, index) => (
          <div key={index} onClick={() => handleMenuItemClick(item)}>
            <motion.div
              initial={{ width: isOpen ? 200 : 60 }}
              animate={{ width: isOpen ? 200 : 60 }}
              className="flex items-center space-x-4 bg-white rounded-full shadow-md h-16 mb-2 cursor-pointer"
            >
              <motion.div
                initial={{ rotate: 0 }}
                animate={{ rotate: isOpen ? 360 : 0 }}
                transition={{ duration: 0.5 }}
                className="flex justify-center items-center w-full"
              >
                <div className="text-lg">{item.icon}</div>
                {isOpen && <span className="text-sm ml-2">{item.text}</span>}
              </motion.div>
            </motion.div>
          </div>
        ))}
        <button onClick={handleLogout} className="flex items-center space-x-4 bg-white rounded-full shadow-md h-16 mb-2 w-full justify-center">
          <FaSignOutAlt className="text-lg" />
          {isOpen && <span className="text-sm ml-2">Logout</span>}
        </button>
      </motion.div>
      {isLogoutModalOpen && <LogoutModal onClose={() => setIsLogoutModalOpen(false)} onConfirm={confirmLogout} />}
      <IframeModal show={isIframeModalOpen} onClose={() => setIsIframeModalOpen(false)} url={iframeUrl} />
    </>
  );
};

export default SideNav;
