// src/components/Modals/EditUserModal.js
import React, { useState, useEffect } from 'react';
import { FaUserEdit, FaUser, FaEnvelope, FaPhone, FaCalendarAlt, FaHome, FaCity, FaBuilding, FaMapPin, FaDollarSign, FaInfoCircle, FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import SuccessModal from './SuccessModal';
import ErrorModal from './ErrorModal';
import './ModalUser.css';
import './modal.css';

const EditUserModal = ({ isOpen, onClose, user, onUpdateUser }) => {
  const [userData, setUserData] = useState({ ...user });
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setUserData({ ...user });
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      onUpdateUser(userData);
      setShowSuccessModal(true);
    } catch (error) {
      setErrorMessage('Failed to update user. Please try again.');
      setShowErrorModal(true);
    }
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-container" onClick={(e) => e.stopPropagation()}>
          <div className="modal-header">
            <FaUserEdit className="modal-icon" />
            <h2 className="modal-title">Edit User</h2>
            <button className="modal-close" onClick={onClose}>X</button>
          </div>
          <form onSubmit={handleSubmit} className="modal-form">
            <div className="form-group">
              <label><FaUser /> First Name:</label>
              <input type="text" name="first_name" value={userData.first_name || ''} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label><FaUser /> Last Name:</label>
              <input type="text" name="last_name" value={userData.last_name || ''} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label><FaCalendarAlt /> Date of Birth:</label>
              <input type="date" name="dob" value={userData.dob || ''} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label><FaHome /> Address:</label>
              <input type="text" name="address" value={userData.address || ''} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label><FaCity /> City:</label>
              <input type="text" name="city" value={userData.city || ''} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label><FaBuilding /> State:</label>
              <input type="text" name="state" value={userData.state || ''} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label><FaMapPin /> Zip Code:</label>
              <input type="text" name="zip" value={userData.zip || ''} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label><FaInfoCircle /> Employment Status:</label>
              <input type="text" name="employment_status" value={userData.employment_status || ''} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label><FaDollarSign /> Annual Income:</label>
              <input type="number" name="annual_income" value={userData.annual_income || ''} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label><FaEnvelope /> Email:</label>
              <input type="email" name="email" value={userData.email || ''} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label><FaPhone /> Phone:</label>
              <input type="text" name="phone" value={userData.phone || ''} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label><FaCalendarAlt /> Member Since:</label>
              <input type="date" name="member_since" value={userData.member_since || ''} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label><FaInfoCircle /> Status:</label>
              <input type="text" name="status" value={userData.status || ''} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label><FaDollarSign /> Balance:</label>
              <input type="number" name="balance" value={userData.balance || ''} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label><FaInfoCircle /> Withdrawal History:</label>
              <textarea name="withdrawal_history" value={userData.withdrawal_history || ''} readOnly />
            </div>
            <div className="form-group">
              <label><FaUser /> Profile Picture:</label>
              <input type="file" name="profile_picture" onChange={handleChange} />
            </div>
            <button type="submit" className="modal-submit-button">Update User</button>
          </form>
        </div>
      </div>

      {showSuccessModal && (
        <SuccessModal
          isOpen={showSuccessModal}
          onClose={() => setShowSuccessModal(false)}
          message="User updated successfully!"
        />
      )}

      {showErrorModal && (
        <ErrorModal
          isOpen={showErrorModal}
          onClose={() => setShowErrorModal(false)}
          message={errorMessage}
        />
      )}
    </>
  );
};

export default EditUserModal;
