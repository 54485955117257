import React, { useState, useRef } from 'react';
import { FaLock, FaEye, FaEyeSlash } from 'react-icons/fa';
import { BallTriangle } from 'react-loader-spinner';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { verify2FA, verifyAuthPin } from '../components/services/authApi';
import wolfImage from '../assets/wolf5.webp';
import VerifyPinModal from '../components/Modals/VerifyPinModal';
import SetupDatabaseModal from '../components/Modals/SetupDatabaseModal';

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState(Array(6).fill(''));
  const [showPin, setShowPin] = useState(false);
  const [twoFaCode, setTwoFaCode] = useState('');
  const [is2FAVerification, setIs2FAVerification] = useState(false);
  const [isDBPinVerification, setIsDBPinVerification] = useState(false);
  const [isSetupDatabaseOpen, setIsSetupDatabaseOpen] = useState(false);
  const navigate = useNavigate();
  const { login, is2FAEnabled } = useAuth();
  const inputRefs = useRef([]);

  const handlePinChange = (value, index) => {
    const newPin = [...pin];
    newPin[index] = value;
    setPin(newPin);

    if (value && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !pin[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const pinValue = pin.join('');
    setLoading(true);
    try {
      const response = await login(pinValue);
      if (response.success) {
        localStorage.setItem('token', response.token);
        if (response.is2FAEnabled) {
          setIs2FAVerification(true);
        } else {
          navigate('/dashboard');
        }
      } else {
        if (!toast.isActive('login-error')) {
          toast.error(response.message || 'Authentication failed: Invalid PIN', { toastId: 'login-error' });
        }
      }
    } catch (error) {
      if (!toast.isActive('login-error')) {
        toast.error(error.response?.data?.message || 'Login error: Authentication failed: Invalid PIN', { toastId: 'login-error' });
      }
    } finally {
      setLoading(false);
    }
  };

  const handle2FAVerification = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await verify2FA(twoFaCode);
      if (response.success) {
        toast.success('2FA verified successfully!');
        setTimeout(() => {
          navigate('/dashboard');
        }, 1000);
      } else {
        if (!toast.isActive('2fa-error')) {
          toast.error(response.message || 'Failed to verify 2FA.', { toastId: '2fa-error' });
        }
      }
    } catch (error) {
      if (!toast.isActive('2fa-error')) {
        toast.error('Failed to verify 2FA.', { toastId: '2fa-error' });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDBPinVerification = async (pin) => {
    setLoading(true);
    try {
      const response = await verifyAuthPin(pin);
      if (response.success) {
        toast.success('DB PIN verified successfully!');
        setIsDBPinVerification(false);
        setIsSetupDatabaseOpen(true);
      } else {
        if (!toast.isActive('dbpin-error')) {
          toast.error(response.message || 'Failed to verify DB PIN.', { toastId: 'dbpin-error' });
        }
      }
    } catch (error) {
      if (!toast.isActive('dbpin-error')) {
        toast.error('Failed to verify DB PIN.', { toastId: 'dbpin-error' });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="min-h-screen flex items-center justify-center bg-gray-100"
      style={{
        backgroundImage: 'url(/img.webp)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <motion.div
        className="bg-white p-8 rounded-lg shadow-md w-full max-w-md relative"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        style={{
          backgroundImage: `url(${wolfImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <h2 className="text-2xl font-bold mb-6 text-center text-white">Admin Login</h2>
        {is2FAVerification ? (
          <form onSubmit={handle2FAVerification}>
            <div className="mb-6">
              <label className="block text-white text-lg font-bold mb-2" htmlFor="twoFaCode">
                Enter Your 2FA Code
              </label>
              <input
                type="text"
                id="twoFaCode"
                value={twoFaCode}
                onChange={(e) => setTwoFaCode(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
              <p className="text-sm font-bold text-white mt-1">Enter your code.</p>
            </div>

            <div className="flex items-center justify-between">
              <motion.button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
                disabled={loading}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                {loading ? (
                  <AnimatePresence>
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      key="loader"
                    >
                      <BallTriangle height={24} width={24} color="#fff" ariaLabel="loading" />
                    </motion.div>
                  </AnimatePresence>
                ) : (
                  'Verify'
                )}
              </motion.button>
            </div>
          </form>
        ) : (
          <form onSubmit={handleLogin}>
            <div className="mb-6">
              <label className="block text-white text-sm font-bold mb-2" htmlFor="pin">
                PIN
              </label>
              <div className="flex items-center border rounded py-2 px-3 shadow focus-within:shadow-outline">
                <FaLock className="text-gray-400 mr-2" />
                <div className="flex">
                  {pin.map((digit, index) => (
                    <input
                      key={index}
                      type={showPin ? 'text' : 'password'}
                      maxLength="1"
                      value={digit}
                      onChange={(e) => handlePinChange(e.target.value, index)}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      ref={(el) => (inputRefs.current[index] = el)}
                      className="w-10 text-center mx-1 border-b-2 border-gray-400 focus:border-blue-500 focus:outline-none"
                    />
                  ))}
                </div>
                <button
                  type="button"
                  onClick={() => setShowPin(!showPin)}
                  className="ml-2 focus:outline-none"
                >
                  {showPin ? <FaEyeSlash className="text-white" /> : <FaEye className="text-white" />}
                </button>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <motion.button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
                disabled={loading}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                {loading ? (
                  <AnimatePresence>
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      key="loader"
                    >
                      <BallTriangle height={24} width={24} color="#fff" ariaLabel="loading" />
                    </motion.div>
                  </AnimatePresence>
                ) : (
                  'Sign In'
                )}
              </motion.button>
              <motion.button
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="button"
                onClick={() => setIsDBPinVerification(true)}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Setup DB
              </motion.button>
            </div>
          </form>
        )}
      </motion.div>
      <VerifyPinModal
        isOpen={isDBPinVerification}
        onRequestClose={() => setIsDBPinVerification(false)}
        onVerify={handleDBPinVerification}
      />
      <SetupDatabaseModal
        isOpen={isSetupDatabaseOpen}
        onRequestClose={() => setIsSetupDatabaseOpen(false)}
      />
    </div>
  );
};

export default Login;
