import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { updateWithdrawRequest } from '../services/api'; 
import './modal.css'; 
import ErrorModal from './ErrorModal';
import SuccessModal from './SuccessModal';

const UpdateWithdrawModal = ({ isOpen, onClose, withdrawRequest }) => {
  const [newStatus, setNewStatus] = useState(withdrawRequest.status);
  const [successMessage, setSuccessMessage] = useState(null); // State for success message
  const [errorMessage, setErrorMessage] = useState(null); // State for error message
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requestData = { withdrawId: withdrawRequest.id, newStatus };

   

    try {
      const response = await updateWithdrawRequest(withdrawRequest.id, newStatus);
      if (response.status === 'success') {
        
        setSuccessMessage('Withdrawal request updated successfully');
        setIsSuccessModalOpen(true);
      } else {
       
        setErrorMessage(response.message); // Set the error message
      }
    } catch (error) {
      console.error('Error updating withdrawal request:', error);
      setErrorMessage('Failed to update withdrawal request'); // Set the error message
    }
  };

  const handleClose = () => {
    setIsSuccessModalOpen(false);
    onClose();
  };

  if (!isOpen || !withdrawRequest) return null;

  return (
    <>
      <div className="modal-overlay">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="text-2xl font-semibold text-gray-800">Update Withdrawal Request</h2>
            <button onClick={onClose} className="modal-close-btn">
              <FaTimes />
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="newStatus">
                  New Status
                </label>
                <select
                  id="newStatus"
                  className="shadow appearance-none border border-gray-300 rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-500"
                  value={newStatus}
                  onChange={(e) => setNewStatus(e.target.value)}
                >
                  <option value="Pending">Pending</option>
                  <option value="Approved">Approved</option>
                  <option value="Rejected">Rejected</option>
                </select>
              </div>
              <div className="flex items-center justify-between">
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300 ease-in-out"
                >
                  Update
                </button>
                <button
                  type="button"
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300 ease-in-out"
                  onClick={onClose}
                >
                  Cancel
                </button>
              </div>
            </form>
            {errorMessage && (
              <div>
                <ErrorModal message={errorMessage} onClose={() => setErrorMessage(null)} />
                
              </div>
            )}
            {isSuccessModalOpen && (
              <div>
                <SuccessModal isOpen={isSuccessModalOpen} onClose={handleClose} message={successMessage} />
                
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateWithdrawModal;
