import React, { useState, useEffect } from 'react';
import { Bar, Line, Pie } from 'react-chartjs-2';
import { FaUsers } from 'react-icons/fa';
import { getUserActivityMetrics } from '../../services/MonitorService';
import './UserActivityGroup.css';
import {
  barChartOptions,
  lineChartOptions,
  pieChartOptions
} from '../chartConfig';

const sections = [
  { id: 'active-users', label: 'Active Users', icon: FaUsers },
  { id: 'user-login-attempts', label: 'User Login Attempts', icon: FaUsers },
  { id: 'user-registration-rates', label: 'User Registration Rates', icon: FaUsers },
  { id: 'user-actions', label: 'User Actions', icon: FaUsers }
];

const UserActivityGroup = () => {
  const [activeSection, setActiveSection] = useState(sections[0].id);
  const [metricsData, setMetricsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        const data = await getUserActivityMetrics();
        setMetricsData(data);
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch user activity metrics:', error);
        setError('Failed to fetch data');
        setLoading(false);
      }
    };

    fetchMetrics();
  }, []);

  const renderContent = () => {
    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    switch (activeSection) {
      case 'active-users':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center">
              <FaUsers className="mr-2" /> Active Users
            </h2>
            <Bar
              data={{
                labels: ['Active Users'],
                datasets: [{ data: [metricsData.active_users], backgroundColor: 'rgba(75, 192, 192, 0.2)', borderColor: 'rgba(75, 192, 192, 1)', borderWidth: 1 }]
              }}
              options={barChartOptions}
            />
          </div>
        );
      case 'user-login-attempts':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center">
              <FaUsers className="mr-2" /> User Login Attempts
            </h2>
            <Line
              data={{
                labels: metricsData.user_login_attempts.labels,
                datasets: [{ data: metricsData.user_login_attempts.data, fill: false, borderColor: 'rgba(75, 192, 192, 1)', tension: 0.1 }]
              }}
              options={lineChartOptions}
            />
          </div>
        );
      case 'user-registration-rates':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center">
              <FaUsers className="mr-2" /> User Registration Rates
            </h2>
            <Pie
              data={{
                labels: metricsData.user_registration_rates.labels,
                datasets: [{ data: metricsData.user_registration_rates.data, backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(153, 102, 255, 0.2)', 'rgba(255, 159, 64, 0.2)'], borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)', 'rgba(255, 159, 64, 1)'], borderWidth: 1 }]
              }}
              options={pieChartOptions}
            />
          </div>
        );
      case 'user-actions':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center">
              <FaUsers className="mr-2" /> User Actions
            </h2>
            <Line
              data={{
                labels: metricsData.user_actions.map(action => action.message),
                datasets: [{ data: metricsData.user_actions.map(action => action.count), fill: false, borderColor: 'rgba(75, 192, 192, 1)', tension: 0.1 }]
              }}
              options={lineChartOptions}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="user-activity-group bg-white shadow-lg rounded-lg p-6">
      <h2 className="text-2xl font-bold flex items-center mb-4">
        <FaUsers className="mr-2" /> User Activity Dashboard
      </h2>
      <div className="navigation mb-4">
        {sections.map((section) => (
          <button
            key={section.id}
            className={`nav-button ${activeSection === section.id ? 'active' : ''}`}
            onClick={() => setActiveSection(section.id)}
          >
            <section.icon className="mr-2" />
            {section.label}
          </button>
        ))}
      </div>
      <div className="content">{renderContent()}</div>
    </div>
  );
};

export default UserActivityGroup;
