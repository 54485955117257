// src/components/Modals/ServerInfoModal.js
import React, { useState } from 'react';
import { FaTimes, FaServer, FaDatabase, FaInfoCircle, FaFire } from 'react-icons/fa';
import ServerDetailsModal from './ServerDetailsModal';
import Section2Modal from './Section2Modal';
import Section3Modal from './Section3Modal';
import Section4Modal from './Section4Modal';
import './ServerInfoModal.css';

const ServerInfoModal = ({ onClose }) => {
  const [showServerDetails, setShowServerDetails] = useState(false);
  const [showSection2, setShowSection2] = useState(false);
  const [showSection3, setShowSection3] = useState(false);
  const [showSection4, setShowSection4] = useState(false);

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <div className="modal-header">
          <h2>Server Information</h2>
          <button className="close-button" onClick={onClose}>
            <FaTimes />
          </button>
        </div>
        <div className="modal-content-no-container">
          <button className="info-button" onClick={() => setShowServerDetails(true)}>
            <FaServer className="icon" />
            <div className="info-text">
              <h3>Server Details</h3>
              <p>View information about the server such as name, PHP version, document root, and server software.</p>
            </div>
          </button>
          <button className="info-button" onClick={() => setShowSection2(true)}>
            <FaDatabase className="icon" />
            <div className="info-text">
              <h3>Setup Database</h3>
              <p>Get guidance on how to set up and configure your database for optimal performance.</p>
            </div>
          </button>
          <button className="info-button" onClick={() => setShowSection3(true)}>
            <FaInfoCircle className="icon" />
            <div className="info-text">
              <h3>Database Details</h3>
              <p>View detailed information about the database including tables, rows, and other statistics.</p>
            </div>
          </button>
          <button className="info-button" onClick={() => setShowSection4(true)}>
            <FaFire className="icon" />
            <div className="info-text">
              <h3>Setup Firebase</h3>
              <p>Learn how to integrate Firebase into your project for authentication, database, and more.</p>
            </div>
          </button>
        </div>
      </div>
      {showServerDetails && <ServerDetailsModal onClose={() => setShowServerDetails(false)} />}
      {showSection2 && <Section2Modal onClose={() => setShowSection2(false)} />}
      {showSection3 && <Section3Modal onClose={() => setShowSection3(false)} />}
      {showSection4 && <Section4Modal onClose={() => setShowSection4(false)} />}
    </div>
  );
};

export default ServerInfoModal;
