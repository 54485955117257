import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { getSeoSettings } from './services/seoApi';

const Seo = ({ pageUrl }) => {
  const [seoData, setSeoData] = useState({
    metaTitle: '',
    metaDescription: '',
    metaKeywords: '',
    ogTitle: '',
    ogDescription: '',
    ogImage: '',
    ogImageAlt: '',
    twitterTitle: '',
    twitterDescription: '',
    twitterImage: '',
    twitterImageAlt: '',
    jsonLd: '',
    canonicalUrl: '',
    robotsContent: ''
  });

  useEffect(() => {
    const fetchSeoData = async () => {
      try {
        const response = await getSeoSettings(pageUrl);
        setSeoData(response.data || {
          metaTitle: '',
          metaDescription: '',
          metaKeywords: '',
          ogTitle: '',
          ogDescription: '',
          ogImage: '',
          ogImageAlt: '',
          twitterTitle: '',
          twitterDescription: '',
          twitterImage: '',
          twitterImageAlt: '',
          jsonLd: '',
          canonicalUrl: '',
          robotsContent: ''
        });
      } catch (error) {
        console.error('Error fetching SEO data:', error);
      }
    };

    fetchSeoData();
  }, [pageUrl]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{seoData.metaTitle}</title>
        <meta name="description" content={seoData.metaDescription} />
        <meta name="keywords" content={seoData.metaKeywords} />
        <meta property="og:title" content={seoData.ogTitle} />
        <meta property="og:description" content={seoData.ogDescription} />
        <meta property="og:image" content={seoData.ogImage} />
        <meta property="og:image:alt" content={seoData.ogImageAlt} />
        <meta name="twitter:title" content={seoData.twitterTitle} />
        <meta name="twitter:description" content={seoData.twitterDescription} />
        <meta name="twitter:image" content={seoData.twitterImage} />
        <meta name="twitter:image:alt" content={seoData.twitterImageAlt} />
        <script type="application/ld+json">{seoData.jsonLd}</script>
        <link rel="canonical" href={seoData.canonicalUrl} />
        <meta name="robots" content={seoData.robotsContent} />
      </Helmet>
    </HelmetProvider>
  );
};

export default Seo;
