import React, { useState, useEffect } from 'react';
import { updateTicketStatus } from '../services/api';
import { FaCheckCircle, FaTimesCircle, FaTimes } from 'react-icons/fa';
import './modal.css';
import ErrorModal from './ErrorModal';
import SuccessModal from './SuccessModal';

const TicketUpdateStatusModal = ({ isOpen, onClose, ticket }) => {
  const [newStatus, setNewStatus] = useState('');
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    if (ticket) {
      setNewStatus(ticket.status);
      
    }
  }, [ticket]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const ticketId = ticket.ticket_number; // Use ticket_number instead of id
      const status = newStatus;
      

      const response = await updateTicketStatus(ticketId, status);
      
      if (response.status === 'success') {
        setSuccessMessage('Ticket status updated successfully');
        setIsSuccessModalOpen(true);
      } else {
        setErrorMessage('Failed to update ticket status');
        setIsErrorModalOpen(true);
      }
    } catch (error) {
      console.error('Error updating ticket status:', error);
      setErrorMessage('Failed to update ticket status');
      setIsErrorModalOpen(true);
    }
  };

  const handleClose = () => {
    setIsSuccessModalOpen(false);
    onClose();
  };

  if (!isOpen || !ticket) return null;

  return (
    <>
      <div className="modal-overlay">
        <div className="modal-content">
          <div className="modal-header">
            <h2>Update Ticket Status</h2>
            <button onClick={onClose}><FaTimes /></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="mb-6">
                <label htmlFor="newTicketStatus">New Ticket Status</label>
                <select
                  id="newTicketStatus"
                  className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-500"
                  value={newStatus}
                  onChange={(e) => setNewStatus(e.target.value)}
                >
                  <option value="Played">Played</option>
                  <option value="Won">Won</option>
                  <option value="Lost">Lost</option>
                </select>
              </div>
              <div className="modal-footer">
                <button type="submit" className="submit flex items-center"><FaCheckCircle className="mr-2" /> Update</button>
                <button type="button" className="cancel flex items-center" onClick={onClose}><FaTimesCircle className="mr-2" /> Cancel</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ErrorModal isOpen={isErrorModalOpen} onClose={() => setIsErrorModalOpen(false)} errorMessage={errorMessage} />
      <SuccessModal isOpen={isSuccessModalOpen} onClose={handleClose} message={successMessage} />
    </>
  );
};

export default TicketUpdateStatusModal;
