import React, { useState } from 'react';
import { Bar, Line, Pie, Doughnut, PolarArea } from 'react-chartjs-2';
import { FaChartLine } from 'react-icons/fa';
import {
  barChartData, barChartOptions,
  lineChartData, lineChartOptions,
  pieChartData, pieChartOptions,
  doughnutChartData, doughnutChartOptions,
  polarAreaChartData, polarAreaChartOptions
} from '../chartConfig';
import './ApplicationMetricsGroup.css';

const sections = [
  { id: 'api-response-times', label: 'API Response Times', icon: FaChartLine },
  { id: 'api-call-volumes', label: 'API Call Volumes', icon: FaChartLine },
  { id: 'rate-limiting-metrics', label: 'Rate Limiting Metrics', icon: FaChartLine },
  { id: 'cache-hits-misses', label: 'Cache Hits/Misses', icon: FaChartLine },
  { id: 'network-activity', label: 'Network Activity', icon: FaChartLine }
];

const ApplicationMetricsGroup = () => {
  const [activeSection, setActiveSection] = useState(sections[0].id);

  const renderContent = () => {
    switch (activeSection) {
      case 'api-response-times':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center">
              <FaChartLine className="mr-2" /> API Response Times
            </h2>
            <Bar data={barChartData} options={barChartOptions} />
          </div>
        );
      case 'api-call-volumes':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center">
              <FaChartLine className="mr-2" /> API Call Volumes
            </h2>
            <Line data={lineChartData} options={lineChartOptions} />
          </div>
        );
      case 'rate-limiting-metrics':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center">
              <FaChartLine className="mr-2" /> Rate Limiting Metrics
            </h2>
            <Pie data={pieChartData} options={pieChartOptions} />
          </div>
        );
      case 'cache-hits-misses':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center">
              <FaChartLine className="mr-2" /> Cache Hits/Misses
            </h2>
            <Doughnut data={doughnutChartData} options={doughnutChartOptions} />
          </div>
        );
      case 'network-activity':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center">
              <FaChartLine className="mr-2" /> Network Activity
            </h2>
            <PolarArea data={polarAreaChartData} options={polarAreaChartOptions} />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="application-metrics-group bg-white shadow-lg rounded-lg p-6">
      <h2 className="text-2xl font-bold flex items-center mb-4">
        <FaChartLine className="mr-2 text-red-500" />
        Application Metrics Dashboard
      </h2>
      <div className="navigation mb-4">
        {sections.map((section) => (
          <button
            key={section.id}
            className={`nav-button ${activeSection === section.id ? 'active' : ''}`}
            onClick={() => setActiveSection(section.id)}
          >
            <section.icon className="mr-2" />
            {section.label}
          </button>
        ))}
      </div>
      <div className="content">{renderContent()}</div>
    </div>
  );
};

export default ApplicationMetricsGroup;
