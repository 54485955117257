import React, { useState, useEffect, useRef } from 'react';
import { FaTimes } from 'react-icons/fa';
import Toastify from 'toastify-js';
import { fetchDatabaseInfo, setupDatabase } from '../services/ServerApi';
import './Section2Modal.css';
import './Section4Modal.css';
import 'toastify-js/src/toastify.css';

const Section2Modal = ({ onClose }) => {
  const [dbInfo, setDbInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [newDbDetails, setNewDbDetails] = useState({ dbHost: '', dbUser: '', dbPass: '', dbName: '' });
  const [setupResponse, setSetupResponse] = useState(null);
  const [progress, setProgress] = useState(0);
  const fetchToastShown = useRef(false);

  useEffect(() => {
    if (!fetchToastShown.current) {
      fetchDatabaseInfoData();
      fetchToastShown.current = true;
    }
  }, []);

  const fetchDatabaseInfoData = async () => {
    Toastify({
      text: "Fetching database information..."
    }).showToast();

    try {
      const data = await fetchDatabaseInfo();
      setDbInfo(data);
      setLoading(false);

      Toastify({
        text: "Database information fetched successfully"
      }).showToast();
    } catch (error) {
      console.error('Error fetching database information:', error);
      setLoading(false);

      Toastify({
        text: "Error fetching database information",
        backgroundColor: "linear-gradient(to right, #ff5f6d, #ffc371)"
      }).showToast();
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewDbDetails({ ...newDbDetails, [name]: value });
  };

  const handleSetupDatabase = async () => {
    setProgress(0);

    Toastify({
      text: "Setting up database..."
    }).showToast();

    try {
      const response = await setupDatabase(newDbDetails);
      setSetupResponse(response);
      setProgress(100);

      Toastify({
        text: "Database setup completed"
      }).showToast();
    } catch (error) {
      console.error('Error setting up database:', error);
      setSetupResponse({ error: 'Failed to setup database' });

      Toastify({
        text: "Error setting up database",
        backgroundColor: "linear-gradient(to right, #ff5f6d, #ffc371)"
      }).showToast();
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="modal-overlay-section4">
      <div className="modal-container-section4">
        <div className="modal-header-section4">
          <h2>Database Setup</h2>
          <button className="close-button-section4" onClick={onClose}>
            <FaTimes />
          </button>
        </div>
        <div className="modal-content-section4">
          <div className="box-section4">
            <h3>Setup New Database</h3>
            <input type="text" name="dbHost" placeholder="DB Host" value={newDbDetails.dbHost} onChange={handleInputChange} />
            <input type="text" name="dbUser" placeholder="DB User" value={newDbDetails.dbUser} onChange={handleInputChange} />
            <input type="password" name="dbPass" placeholder="DB Pass" value={newDbDetails.dbPass} onChange={handleInputChange} />
            <input type="text" name="dbName" placeholder="DB Name" value={newDbDetails.dbName} onChange={handleInputChange} />
            <button onClick={handleSetupDatabase}>Setup Database</button>
            <div className="progress-bar-section4">
              <div className="progress" style={{ width: `${progress}%` }}></div>
            </div>
            {setupResponse && (
              <div className="setup-response">
                {setupResponse.error ? (
                  <span className="error">{setupResponse.error}</span>
                ) : (
                  <span className="success">Database setup successful!</span>
                )}
              </div>
            )}
          </div>
          <div className="box-section4">
            <h3>Current Database Info</h3>
            {dbInfo ? (
              <pre>{JSON.stringify(dbInfo, null, 2)}</pre>
            ) : (
              <p>No database information found.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section2Modal;
