import React from 'react';
import { FaTimes } from 'react-icons/fa';
import './HowToSetupModal.css';

const HowToSetupModal = ({ onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <div className="modal-header">
          <h2>How to Setup Firebase</h2>
          <button className="close-button" onClick={onClose}>
            <FaTimes />
          </button>
        </div>
        <div className="modal-content">
          <h3>Step-by-Step Instructions</h3>
          <ol>
            <li>Go to the Firebase Console and log in with your account.</li>
            <li>Create a new project or select an existing project.</li>
            <li>Navigate to Project Settings and select the Service Accounts tab.</li>
            <li>Generate a new private key and download the JSON file.</li>
            <li>Save the JSON file as <code>service-account.json</code> in the root directory of your project.</li>
            <li>Use the Firebase CLI to authenticate with the new service account:
              <pre><code>firebase login</code></pre>
            </li>
            <li>Set the Firebase project using:
              <pre><code>firebase use --add [project_id]</code></pre>
            </li>
            <li>Run the setup script to configure and deploy the rules.</li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default HowToSetupModal;
