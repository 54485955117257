// src/components/Section.js
import React, { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import './Section.css';

const Section = ({ name, children, activeSection, setActiveSection, clickedSection }) => {
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.5, // Increase threshold to reduce sensitivity
  });

  const elementRef = useRef();

  useEffect(() => {
    if (elementRef.current) {
      elementRef.current.id = name;
    }
  }, [name]);

  useEffect(() => {
    if (inView && activeSection !== name && !clickedSection) {
      setActiveSection(name);
    }
  }, [inView, activeSection, name, setActiveSection, clickedSection]);

  return (
    <div
      ref={(node) => {
        ref(node);
        elementRef.current = node;
      }}
      className={`element ${activeSection === name ? 'zoom active' : ''}`}
    >
      {children}
    </div>
  );
};

export default Section;
