import axios from 'axios';

const API_URL = 'https://homeaidrelief.com/api';

const createApiUrl = (endpoint) => `${API_URL}/${endpoint}.php`;

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

// Fetch users count
export const fetchUsersCount = async () => {
  try {
    const response = await api.get(createApiUrl('users'));
    return response.data;
  } catch (error) {
    console.error('Error fetching users count:', error.response?.data || error.message);
    throw error;
  }
};



export const deleteUser = async (userId) => {
  try {
    const response = await api.post(createApiUrl('User/deleteUser'), { user_id: userId });
    return response.data;
  } catch (error) {
    console.error('Error deleting user:', error);
    return { status: 'error', message: 'Failed to delete user' };
  }
};

export const fetchAllUsers = async () => {
  try {
    const response = await api.post(createApiUrl('User/fetchAllUsers'));
    return response.data;
  } catch (error) {
    console.error('Error fetching all users:', error.response?.data || error.message);
    throw error;
  }
};

export const updateAllUser = async (userData) => {
  try {
    const response = await api.post(createApiUrl('User/updateAllUser'), userData);
    return response.data;
  } catch (error) {
    console.error('Error updating user:', error.response?.data || error.message);
    throw error;
  }
};

// Fetch top up requests count
export const fetchTopUpRequestsCount = async () => {
  try {
    const response = await api.get(createApiUrl('top_up_requests'));
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Fetch tickets played count
export const fetchTicketsPlayedCount = async () => {
  try {
    const response = await api.get(createApiUrl('tickets'));
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Fetch withdraw requests count
export const fetchWithdrawRequestsCount = async () => {
  try {
    const response = await api.get(createApiUrl('withdraw_requests'));
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Announcements API
export const fetchAnnouncements = async () => {
  try {
    const response = await api.get(createApiUrl('Announcements/getAnnouncements'));
    return response.data;
  } catch (error) {
    console.error('Error fetching announcements:', error);
    throw error;
  }
};

export const createAnnouncement = async (message) => {
  try {
    const response = await api.post(createApiUrl('Announcements/createAnnouncement'), { message });
    return response.data;
  } catch (error) {
    console.error('Error creating announcement:', error);
    throw error;
  }
};

export const updateAnnouncement = async (id, message) => {
  try {
    const response = await api.post(createApiUrl('Announcements/updateAnnouncement'), { id, message });
    return response.data;
  } catch (error) {
    console.error('Error updating announcement:', error);
    throw error;
  }
};

export const deleteAnnouncement = async (id) => {
  try {
    const response = await api.post(createApiUrl('Announcements/deleteAnnouncement'), { id });
    return response.data;
  } catch (error) {
    console.error('Error deleting announcement:', error);
    throw error;
  }
};

// News API
export const fetchNews = async () => {
  try {
    const response = await api.get(createApiUrl('News/getNews'));
    return response.data;
  } catch (error) {
    console.error('Error fetching news:', error);
    throw error;
  }
};

export const createNews = async (title, message) => {
  try {
    const response = await api.post(createApiUrl('News/createNews'), { title, message });
    return response.data;
  } catch (error) {
    console.error('Error creating news:', error);
    throw error;
  }
};

export const updateNews = async (id, title, message) => {
  try {
    const response = await api.post(createApiUrl('News/updateNews'), { id, title, message });
    return response.data;
  } catch (error) {
    console.error('Error updating news:', error);
    throw error;
  }
};

export const deleteNews = async (id) => {
  try {
    const response = await api.post(createApiUrl('News/deleteNews'), { id });
    return response.data;
  } catch (error) {
    console.error('Error deleting news:', error);
    return { success: false, error: error.message };
  }
};

// Vouchers API
export const fetchVouchers = async () => {
  try {
    const response = await api.get(createApiUrl('Vouchers/getVouchers'));
    return response.data;
  } catch (error) {
    console.error('Error fetching vouchers:', error);
    throw error;
  }
};

export const generateVoucher = async (user_id = null) => {
  try {
    const response = await api.post(createApiUrl('Vouchers/createVoucher'), { user_id });
    return response.data;
  } catch (error) {
    console.error('Error generating voucher:', error);
    throw error;
  }
};

export const updateVoucher = async (id, code, value, expiration_date, status, user_id) => {
  try {
    const response = await api.post(createApiUrl('Vouchers/updateVoucher'), { id, code, value, expiration_date, status, user_id });
    return response.data;
  } catch (error) {
    console.error('Error updating voucher:', error);
    throw error;
  }
};

export const deleteVoucher = async (id) => {
  try {
    const response = await api.post(createApiUrl('Vouchers/deleteVoucher'), { id });
    return response.data;
  } catch (error) {
    console.error('Error deleting voucher:', error);
    throw error;
  }
};

// Fetch messages
export const fetchMessages = async () => {
  try {
    const response = await api.get(createApiUrl('Messages/getMessages'));
    return response.data;
  } catch (error) {
    console.error('Error fetching messages:', error);
    throw error;
  }
};

// Reply to a message
export const replyMessage = async (replyData) => {
  try {
    const response = await api.post(createApiUrl('Messages/replyMessage'), replyData);
    return response.data;
  } catch (error) {
    console.error('Error replying to message:', error);
    throw error;
  }
};

export const deleteMessage = async (id) => {
  try {
    const response = await api.post(createApiUrl('Messages/deleteMessage'), { id });
    return response.data;
  } catch (error) {
    console.error('Error deleting message:', error);
    throw error;
  }
};

// Fetch user names
export const fetchUserNames = async () => {
  try {
    const response = await api.get(createApiUrl('Messages/getUserNames'));
    return response.data;
  } catch (error) {
    console.error('Error fetching user names:', error);
    throw error;
  }
};

// Fetch users list
export const fetchUsers = async () => {
  try {
    const response = await api.post(createApiUrl('User/list_users'));
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Update user balance
export const updateUserBalance = async (userId, balance) => {
  try {
    const response = await api.post(createApiUrl('User/update_user_balance'), { userId, balance });
    return response.data;
  } catch (error) {
    console.error('Error updating user balance:', error);
    throw error;
  }
};

// Update user status
export const updateUserStatus = async (userId, status) => {
  try {
    const response = await api.post(createApiUrl('User/update_user_status'), { userId, status });
    return response.data;
  } catch (error) {
    console.error('Error updating user status:', error);
    throw error;
  }
};

// Update ticket status
export const updateTicketStatus = async (ticketId, status) => {
  try {
    const response = await api.post(createApiUrl('User/update_ticket'), { ticketId, status });
    return response.data;
  } catch (error) {
    console.error('Error updating ticket status:', error);
    throw error;
  }
};

// Update top-up request
export const updateTopUpRequest = async (requestData) => {
  try {
    const response = await api.post(createApiUrl('User/update_top_up_request'), requestData);
    return response.data;
  } catch (error) {
    console.error('Error updating top-up request:', error);
    throw error;
  }
};

export const updateWithdrawRequest = async (withdrawId, newStatus) => {
  try {
    const response = await api.post(createApiUrl('User/updateWithdrawRequest'), { withdrawId, newStatus });
    return response.data;
  } catch (error) {
    console.error('Error updating withdraw request:', error);
    throw error;
  }
};
