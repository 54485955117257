import React, { useState } from 'react';
import { Bar, Line, Pie } from 'react-chartjs-2';
import { FaLock } from 'react-icons/fa';
import { barChartData, barChartOptions, lineChartData, lineChartOptions, pieChartData, pieChartOptions } from '../chartConfig';
import './SecurityMonitoringGroup.css';

const sections = [
  { id: 'failed-login-attempts', label: 'Failed Login Attempts', icon: FaLock },
  { id: 'suspicious-activity', label: 'Suspicious Activity', icon: FaLock },
  { id: 'access-logs', label: 'Access Logs', icon: FaLock },
  { id: 'vulnerability-scans', label: 'Vulnerability Scans', icon: FaLock }
];

const SecurityMonitoringGroup = () => {
  const [activeSection, setActiveSection] = useState(sections[0].id);

  const renderContent = () => {
    switch (activeSection) {
      case 'failed-login-attempts':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center">
              <FaLock className="mr-2" /> Failed Login Attempts
            </h2>
            <Bar data={barChartData} options={barChartOptions} />
          </div>
        );
      case 'suspicious-activity':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center">
              <FaLock className="mr-2" /> Suspicious Activity
            </h2>
            <Line data={lineChartData} options={lineChartOptions} />
          </div>
        );
      case 'access-logs':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center">
              <FaLock className="mr-2" /> Access Logs
            </h2>
            <Pie data={pieChartData} options={pieChartOptions} />
          </div>
        );
      case 'vulnerability-scans':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center">
              <FaLock className="mr-2" /> Vulnerability Scans
            </h2>
            <Line data={lineChartData} options={lineChartOptions} />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="security-monitoring-group bg-white shadow-lg rounded-lg p-6">
      <h2 className="text-2xl font-bold flex items-center mb-4">
        <FaLock className="mr-2" /> Security Monitoring Dashboard
      </h2>
      <div className="navigation mb-4">
        {sections.map((section) => (
          <button
            key={section.id}
            className={`nav-button ${activeSection === section.id ? 'active' : ''}`}
            onClick={() => setActiveSection(section.id)}
          >
            <section.icon className="mr-2" />
            {section.label}
          </button>
        ))}
      </div>
      <div className="content">{renderContent()}</div>
    </div>
  );
};

export default SecurityMonitoringGroup;
