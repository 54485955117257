import React, { useState, useEffect } from 'react';
import { updateTopUpRequest } from '../services/api';
import { FaCheckCircle, FaTimesCircle, FaTimes } from 'react-icons/fa';
import './modal.css';
import ErrorModal from './ErrorModal';
import SuccessModal from './SuccessModal';

const TopUpRequestModal = ({ isOpen, onClose, topUpRequest }) => {
  const [newStatus, setNewStatus] = useState('');
  const [amount, setAmount] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    if (topUpRequest) {
      setNewStatus(topUpRequest.status);
      setAmount(topUpRequest.amount);
    }
  }, [topUpRequest]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requestData = { requestId: topUpRequest.id, status: newStatus, amount: parseFloat(amount) };

    

    try {
      const response = await updateTopUpRequest(requestData);
      if (response.status === 'success') {
        
        setSuccessMessage('Top-up request updated successfully');
        setShowSuccessModal(true);
       
      } else {
        
        setErrorMessage(response.message || 'Failed to update top-up request');
        setShowErrorModal(true);
       
      }
    } catch (error) {
      console.error('Error updating top-up request:', error);
      setErrorMessage('Failed to update top-up request');
      setShowErrorModal(true);
     
    }
  };

  const handleClose = () => {
    setShowSuccessModal(false);
    onClose();
  };

  useEffect(() => {
    if (errorMessage) {
      setShowErrorModal(true);
    } else {
      setShowErrorModal(false);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (successMessage) {
      setShowSuccessModal(true);
    } else {
      setShowSuccessModal(false);
    }
  }, [successMessage]);

  if (!isOpen || !topUpRequest) return null;

  return (
    <>
      <div className="modal-overlay">
        <div className="modal-content">
          <div className="modal-header">
            <h2>Update Top-Up Request</h2>
            <button onClick={onClose}><FaTimes /></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label htmlFor="topUpAmount">Amount</label>
                <input
                  type="number"
                  id="topUpAmount"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="topUpStatus">Status</label>
                <select
                  id="topUpStatus"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={newStatus}
                  onChange={(e) => setNewStatus(e.target.value)}
                >
                  <option value="Pending">Pending</option>
                  <option value="Approved">Approved</option>
                  <option value="Rejected">Rejected</option>
                  <option value="Voucher">Voucher</option>
                </select>
              </div>
              <div className="modal-footer">
                <button type="submit" className="submit flex items-center"><FaCheckCircle className="mr-2" /> Update</button>
                <button type="button" className="cancel flex items-center" onClick={onClose}><FaTimesCircle className="mr-2" /> Cancel</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {showErrorModal && (
        <div>
          <ErrorModal message={errorMessage} onClose={() => setErrorMessage('')} />
         
        </div>
      )}
      {showSuccessModal && (
        <div>
          <SuccessModal isOpen={showSuccessModal} onClose={handleClose} message={successMessage} />
          
        </div>
      )}
    </>
  );
};

export default TopUpRequestModal;
