import axios from 'axios';

const API_BASE_URL = 'https://homeaidrelief.com/api/auth';
const createApiUrl = (endpoint) => `${API_BASE_URL}/${endpoint}.php`;

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor to include the JWT token for other requests
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Login function
export const verifyAuthPin = async (pin) => {
  try {
    const response = await axios.post(createApiUrl('auth'), { pin }); // Direct axios post for login without token
    return response.data;
  } catch (error) {
    console.error(`Error verifying PIN: ${error.message}`);
    throw error;
  }
};

// Fetch 2FA status
export const fetch2FAStatus = async () => {
  try {
    const response = await api.post(createApiUrl('2fa_status'));
    return response.data;
  } catch (error) {
    console.error('Error fetching 2FA status:', error);
    throw error;
  }
};

// Enable 2FA
export const enable2FA = async () => {
  try {
    const response = await api.post(createApiUrl('enable_2fa'));
    return response.data;
  } catch (error) {
    console.error('Error enabling 2FA:', error);
    throw error;
  }
};

// Disable 2FA
export const disable2FA = async (twoFaCode) => {
  try {
    const response = await api.post(createApiUrl('disable_2fa'), { twoFaCode });
    return response.data;
  } catch (error) {
    console.error('Error disabling 2FA:', error);
    throw error;
  }
};

// Verify 2FA code
export const verify2FA = async (code) => {
  try {
    const response = await api.post(createApiUrl('verify_2fa'), { code });
    return response.data;
  } catch (error) {
    console.error('Error verifying 2FA:', error);
    throw error;
  }
};

