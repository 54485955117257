import React, { createContext, useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { verifyAuthPin, fetch2FAStatus, enable2FA, disable2FA, verify2FA } from '../components/services/authApi';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const storedAuthState = localStorage.getItem('isAuthenticated');
    return storedAuthState ? JSON.parse(storedAuthState) : false;
  });
  const [is2FAEnabled, setIs2FAEnabled] = useState(false);

  useEffect(() => {
    localStorage.setItem('isAuthenticated', JSON.stringify(isAuthenticated));
  }, [isAuthenticated]);

  const login = async (pin) => {
    try {
      const response = await verifyAuthPin(pin);

      if (response.success) {
        setIsAuthenticated(true);
        setIs2FAEnabled(response.is2FAEnabled);
        localStorage.setItem('token', response.token); // Store token in localStorage
        toast.success('Authentication successful');
      } else {
        throw new Error(response.message);
      }

      return response;
    } catch (error) {
      toast.error(`Login error: ${error.message}`);
      return { success: false, message: error.message };
    }
  };

  const verify2FA = async (code) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No auth token found');

      const response = await verify2FA(code);
      if (response.success) {
        toast.success('2FA verification successful');
        return { success: true };
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      toast.error(`2FA verification error: ${error.message}`);
      return { success: false, message: error.message };
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('token'); // Remove token from localStorage
    toast.info('Logged out successfully');
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, is2FAEnabled, setIs2FAEnabled, setIsAuthenticated, login, verify2FA, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
