import React, { useState, useRef } from 'react';
import Modal from 'react-modal';
import { toast, ToastContainer } from 'react-toastify';
import { motion } from 'framer-motion';
import 'react-toastify/dist/ReactToastify.css';

const ChangePinModal = ({ isOpen, onRequestClose }) => {
  const [currentPin, setCurrentPin] = useState(Array(6).fill(''));
  const [newPin, setNewPin] = useState(Array(6).fill(''));
  const [confirmNewPin, setConfirmNewPin] = useState(Array(6).fill(''));

  const currentPinRefs = useRef([]);
  const newPinRefs = useRef([]);
  const confirmNewPinRefs = useRef([]);

  const handlePinChange = (pinArray, setPinArray, index, value, refs) => {
    if (/^\d*$/.test(value)) {
      const newPinArray = [...pinArray];
      newPinArray[index] = value.slice(-1);
      setPinArray(newPinArray);

      // Move to the next input box if the current one is filled
      if (value && index < 5) {
        refs.current[index + 1].focus();
      }
    }
  };

  const handleBackspace = (pinArray, setPinArray, index, refs) => {
    const newPinArray = [...pinArray];
    newPinArray[index] = '';
    setPinArray(newPinArray);

    // Move to the previous input box if the current one is empty
    if (index > 0) {
      refs.current[index - 1].focus();
    }
  };

  const handleChangePin = async () => {
    const currentPinValue = currentPin.join('');
    const newPinValue = newPin.join('');
    const confirmNewPinValue = confirmNewPin.join('');

    if (newPinValue.length !== 6) {
      toast.error('New PIN must be exactly 6 digits.');
      return;
    }

    if (newPinValue !== confirmNewPinValue) {
      toast.error('New PIN and confirmation PIN do not match.');
      return;
    }

    try {
      const response = await fetch('https://homeaidrelief.com/api/auth/change_pin.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ currentPin: currentPinValue, newPin: newPinValue })
      });

      const data = await response.json();

      if (data.success) {
        toast.success(data.message);
        setTimeout(() => {
          onRequestClose();
        }, 2000); // 2 seconds delay before closing the modal
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error('Error changing PIN:', error);
      toast.error('Failed to change PIN.');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Change PIN"
      className="bg-white p-8 rounded-lg shadow-md w-full max-w-md mx-auto mt-24 relative z-50"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center backdrop-filter backdrop-blur-sm"
    >
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ marginTop: '50px' }}
      />
      <h2 className="text-2xl mb-6 text-center">Change PIN</h2>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Current PIN</label>
        <div className="flex space-x-2">
          {currentPin.map((digit, index) => (
            <input
              key={index}
              type="password"
              value={digit}
              onChange={(e) => handlePinChange(currentPin, setCurrentPin, index, e.target.value, currentPinRefs)}
              onKeyDown={(e) => e.key === 'Backspace' && handleBackspace(currentPin, setCurrentPin, index, currentPinRefs)}
              className="w-12 p-3 border border-gray-300 rounded-lg text-center shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              maxLength="1"
              ref={(el) => (currentPinRefs.current[index] = el)}
            />
          ))}
        </div>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">New PIN</label>
        <div className="flex space-x-2">
          {newPin.map((digit, index) => (
            <input
              key={index}
              type="password"
              value={digit}
              onChange={(e) => handlePinChange(newPin, setNewPin, index, e.target.value, newPinRefs)}
              onKeyDown={(e) => e.key === 'Backspace' && handleBackspace(newPin, setNewPin, index, newPinRefs)}
              className="w-12 p-3 border border-gray-300 rounded-lg text-center shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              maxLength="1"
              ref={(el) => (newPinRefs.current[index] = el)}
            />
          ))}
        </div>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Confirm New PIN</label>
        <div className="flex space-x-2">
          {confirmNewPin.map((digit, index) => (
            <input
              key={index}
              type="password"
              value={digit}
              onChange={(e) => handlePinChange(confirmNewPin, setConfirmNewPin, index, e.target.value, confirmNewPinRefs)}
              onKeyDown={(e) => e.key === 'Backspace' && handleBackspace(confirmNewPin, setConfirmNewPin, index, confirmNewPinRefs)}
              className="w-12 p-3 border border-gray-300 rounded-lg text-center shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              maxLength="1"
              ref={(el) => (confirmNewPinRefs.current[index] = el)}
            />
          ))}
        </div>
      </div>
      <div className="flex justify-between">
        <motion.button
          onClick={handleChangePin}
          className="py-2 px-4 rounded bg-blue-500 hover:bg-blue-700 text-white font-bold focus:outline-none focus:ring-2 focus:ring-blue-500"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Change PIN
        </motion.button>
        <motion.button
          onClick={onRequestClose}
          className="py-2 px-4 rounded bg-gray-500 hover:bg-gray-700 text-white font-bold focus:outline-none focus:ring-2 focus:ring-gray-500"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Cancel
        </motion.button>
      </div>
    </Modal>
  );
};

export default ChangePinModal;
