import React from 'react';
import ReactDOM from 'react-dom/client'; // Correct import
import App from './App';
import Modal from 'react-modal';
import './index.css'; // or your stylesheet
import { AuthProvider } from './context/AuthContext';
import 'react-toastify/dist/ReactToastify.css';
import './styles/custom.css';

// Set the app element for React Modal
Modal.setAppElement('#root');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      
      <App />
    </AuthProvider>
  </React.StrictMode>
);