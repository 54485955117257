import React, { useState, useEffect } from 'react';
import UserUpdateBalanceModal from '../Modals/UserUpdateBalanceModal';
import UserUpdateStatusModal from '../Modals/UserUpdateStatusModal';
import TicketUpdateStatusModal from '../Modals/TicketUpdateStatusModal';
import TopUpRequestModal from '../Modals/TopUpRequestModal';
import UpdateWithdrawModal from '../Modals/UpdateWithdrawModal'; // Import the UpdateWithdrawModal
import { fetchUsers } from '../services/api'; // Import the fetchUsers function
import { FaUser, FaDollarSign, FaTicketAlt, FaArrowUp, FaPen, FaTimes, FaMoneyCheckAlt, FaIdBadge, FaCheckCircle } from 'react-icons/fa';
import '../Modals/Details.css'; // Real Path

const UserDetails = ({ user, onClose }) => {
  const [isBalanceModalOpen, setBalanceModalOpen] = useState(false);
  const [isStatusModalOpen, setStatusModalOpen] = useState(false);
  const [isTicketModalOpen, setTicketModalOpen] = useState(false);
  const [isTopUpModalOpen, setTopUpModalOpen] = useState(false);
  const [isWithdrawModalOpen, setWithdrawModalOpen] = useState(false); // State for withdraw modal
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [selectedTopUpRequest, setSelectedTopUpRequest] = useState(null);
  const [selectedWithdrawRequest, setSelectedWithdrawRequest] = useState(null); // State for selected withdraw request

  const [userData, setUserData] = useState(user); // Initialize with the provided user data

  useEffect(() => {
    let intervalId;

    const fetchData = async () => {
      try {
        const data = await fetchUsers();
        
        if (data.status === 'success' && Array.isArray(data.users)) {
          const currentUserData = data.users.find(u => u.id === user.id);
          if (currentUserData) {
            setUserData(currentUserData);
          } else {
          }
        } else {
        }
      } catch (error) {
      }
    };

    fetchData(); // Initial fetch

    intervalId = setInterval(fetchData, 100); // Fetch every 5 seconds

    return () => clearInterval(intervalId); // Cleanup interval on unmount or modal close
  }, [user.id]); // Add user.id as a dependency to re-fetch if the user changes

  const openTicketModal = (ticket) => {
    setSelectedTicket(ticket);
    setTicketModalOpen(true);
  };

  const openTopUpModal = (topUp) => {
    setSelectedTopUpRequest(topUp);
    setTopUpModalOpen(true);
  };

  const openWithdrawModal = (withdraw) => {
    setSelectedWithdrawRequest(withdraw);
    setWithdrawModalOpen(true);
  };

  return (
    <div className="details-overlay">
      <div className="details-content">
        <div className="details-header">
          <h2 className="text-2xl font-semibold text-gray-800 flex items-center">
            <FaUser className="mr-3 text-green-500" /> User Details
          </h2>
          <button onClick={onClose}>
            <FaTimes />
          </button>
        </div>
        <div className="details-body">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="flex flex-wrap items-center mb-4">
              <FaIdBadge className="mr-2 text-gray-600" />
              <p className="mr-4"><strong>ID:</strong> {userData.id}</p>
              <FaUser className="mr-2 text-gray-600" />
              <p className="mr-4"><strong>First Name:</strong> {userData.first_name}</p>
              <FaUser className="mr-2 text-gray-600" />
              <p className="mr-4"><strong>Last Name:</strong> {userData.last_name}</p>
              <FaCheckCircle className="mr-2 text-gray-600" />
              <p className="mr-4"><strong>Status:</strong> {userData.status}</p>
              <button
                onClick={() => setStatusModalOpen(true)}
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out flex items-center mt-4"
              >
                <FaPen className="mr-2" /> Update Status
              </button>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-4">Financial Information</h3>
              <p className="mb-2"><strong>Balance:</strong> {userData.balance}</p>
              <button
                onClick={() => setBalanceModalOpen(true)}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out flex items-center mt-4"
              >
                <FaDollarSign className="mr-2" /> Update Balance
              </button>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8">
            <div>
              <h3 className="text-xl font-semibold mb-4 flex items-center">
                <FaTicketAlt className="mr-2 text-yellow-500" /> Tickets
              </h3>
              <ul className="mb-6 space-y-2">
                {userData.tickets && userData.tickets.map(ticket => (
                  <li key={ticket.ticket_number} className="flex items-center justify-between p-3 bg-gray-100 rounded-lg shadow-sm">
                    <span>{ticket.ticket_number} - {ticket.status}</span>
                    <button
                      onClick={() => openTicketModal(ticket)}
                      className="text-blue-500 hover:text-blue-700 transition duration-300 ease-in-out flex items-center"
                    >
                      <FaPen className="ml-2" /> Update Status
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-4 flex items-center">
                <FaArrowUp className="mr-2 text-purple-500" /> Top-Up Requests
              </h3>
              <ul className="space-y-2">
                {userData.top_ups && userData.top_ups.map(topUp => (
                  <li key={topUp.id} className="flex items-center justify-between p-3 bg-gray-100 rounded-lg shadow-sm">
                    <span>{topUp.method} - {topUp.amount} - {topUp.status}</span>
                    <button
                      onClick={() => openTopUpModal(topUp)}
                      className="text-blue-500 hover:text-blue-700 transition duration-300 ease-in-out flex items-center"
                    >
                      <FaPen className="ml-2" /> Update Request
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-4 flex items-center">
                <FaMoneyCheckAlt className="mr-2 text-red-500" /> Withdraw Requests
              </h3>
              <ul className="space-y-2">
                {userData.withdraws && userData.withdraws.map(withdraw => (
                  <li key={withdraw.id} className="flex items-center justify-between p-3 bg-gray-100 rounded-lg shadow-sm">
                    <span>{withdraw.type} - {withdraw.amount} - {withdraw.status}</span>
                    <button
                      onClick={() => openWithdrawModal(withdraw)}
                      className="text-blue-500 hover:text-blue-700 transition duration-300 ease-in-out flex items-center"
                    >
                      <FaPen className="ml-2" /> Update Request
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="details-footer">
          <button onClick={onClose} className="cancel">Close</button>
        </div>
        <UserUpdateBalanceModal
          isOpen={isBalanceModalOpen}
          onClose={() => setBalanceModalOpen(false)}
          userId={userData.id}
          currentBalance={userData.balance}
        />
        <UserUpdateStatusModal
          isOpen={isStatusModalOpen}
          onClose={() => setStatusModalOpen(false)}
          userId={userData.id}
          currentStatus={userData.status}
        />
        {selectedTicket && (
          <TicketUpdateStatusModal
            isOpen={isTicketModalOpen}
            onClose={() => setTicketModalOpen(false)}
            ticket={selectedTicket}
          />
        )}
        {selectedTopUpRequest && (
          <TopUpRequestModal
            isOpen={isTopUpModalOpen}
            onClose={() => setTopUpModalOpen(false)}
            topUpRequest={selectedTopUpRequest}
          />
        )}
        {selectedWithdrawRequest && (
          <UpdateWithdrawModal
            isOpen={isWithdrawModalOpen}
            onClose={() => setWithdrawModalOpen(false)}
            withdrawRequest={selectedWithdrawRequest}
          />
        )}
      </div>
    </div>
  );
};

export default UserDetails;
