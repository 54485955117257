import React, { useState } from 'react';
import { Bar, Line, Doughnut, Radar } from 'react-chartjs-2';
import { FaBug } from 'react-icons/fa';
import {
  barChartData, barChartOptions,
  lineChartData, lineChartOptions,
  doughnutChartData, doughnutChartOptions,
  radarChartData, radarChartOptions
} from '../chartConfig';
import './ErrorTrackingGroup.css';

const sections = [
  { id: 'javascript-errors', label: 'JavaScript Errors', icon: FaBug },
  { id: 'backend-errors', label: 'Backend Errors', icon: FaBug },
  { id: 'log-entries', label: 'Log Entries', icon: FaBug },
  { id: 'api-request-failures', label: 'API Request Failures', icon: FaBug }
];

const ErrorTrackingGroup = () => {
  const [activeSection, setActiveSection] = useState(sections[0].id);

  const renderContent = () => {
    switch (activeSection) {
      case 'javascript-errors':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center">
              <FaBug className="mr-2" /> JavaScript Errors
            </h2>
            <Bar data={barChartData} options={barChartOptions} />
          </div>
        );
      case 'backend-errors':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center">
              <FaBug className="mr-2" /> Backend Errors
            </h2>
            <Line data={lineChartData} options={lineChartOptions} />
          </div>
        );
      case 'log-entries':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center">
              <FaBug className="mr-2" /> Log Entries
            </h2>
            <Doughnut data={doughnutChartData} options={doughnutChartOptions} />
          </div>
        );
      case 'api-request-failures':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center">
              <FaBug className="mr-2" /> API Request Failures
            </h2>
            <Radar data={radarChartData} options={radarChartOptions} />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="error-tracking-group bg-white shadow-lg rounded-lg p-6">
      <h2 className="text-2xl font-bold flex items-center mb-4">
        <FaBug className="mr-2" /> Error Tracking Dashboard
      </h2>
      <div className="navigation mb-4">
        {sections.map((section) => (
          <button
            key={section.id}
            className={`nav-button ${activeSection === section.id ? 'active' : ''}`}
            onClick={() => setActiveSection(section.id)}
          >
            <section.icon className="mr-2" />
            {section.label}
          </button>
        ))}
      </div>
      <div className="content">{renderContent()}</div>
    </div>
  );
};

export default ErrorTrackingGroup;
