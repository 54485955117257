import React from 'react';
import { Link } from 'react-scroll';
import { FaMicrochip, FaClock, FaDatabase, FaUsers, FaBug, FaLock, FaChartLine, FaTools } from 'react-icons/fa';
import './MonitorNav.css';

const MonitorNavTop = ({ setActiveSection }) => {
  const navItems = [
    { id: 'server-health', label: 'Server Health', icon: <FaMicrochip /> },
    { id: 'application-performance', label: 'App Performance', icon: <FaClock /> },
    { id: 'database-performance', label: 'Database Performance', icon: <FaDatabase /> },
    { id: 'user-activity', label: 'User Activity', icon: <FaUsers /> },
    { id: 'error-tracking', label: 'Error Tracking', icon: <FaBug /> },
    { id: 'security-monitoring', label: 'Security Monitoring', icon: <FaLock /> },
    { id: 'application-metrics', label: 'App Metrics', icon: <FaChartLine /> },
    { id: 'resource-usage', label: 'Resource Usage', icon: <FaTools /> },
  ];

  const handleClick = (id) => {
    setActiveSection(id);
  };

  return (
    <div className="monitor-nav monitor-nav-top">
      <div className="monitor-nav-container">
        {navItems.map(item => (
          <Link
            key={item.id}
            to={item.id}
            smooth={true}
            duration={500}
            className="monitor-nav-item"
            onClick={() => handleClick(item.id)}
          >
            {item.icon} <span>{item.label}</span>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default MonitorNavTop;
