import React, { useState } from 'react';
import { Bar, Line, Pie } from 'react-chartjs-2';
import { FaSearch } from 'react-icons/fa';
import { barChartData, barChartOptions, lineChartData, lineChartOptions, pieChartData, pieChartOptions } from '../chartConfig';
import './SeoMetricsGroup.css';

const sections = [
  { id: 'page-load-times', label: 'Page Load Times', icon: FaSearch },
  { id: 'crawl-errors', label: 'Crawl Errors', icon: FaSearch },
  { id: 'keyword-rankings', label: 'Keyword Rankings', icon: FaSearch }
];

const SeoMetricsGroup = () => {
  const [activeSection, setActiveSection] = useState(sections[0].id);

  const renderContent = () => {
    switch (activeSection) {
      case 'page-load-times':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center">
              <FaSearch className="mr-2" /> Page Load Times
            </h2>
            <Bar data={barChartData} options={barChartOptions} />
          </div>
        );
      case 'crawl-errors':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center">
              <FaSearch className="mr-2" /> Crawl Errors
            </h2>
            <Line data={lineChartData} options={lineChartOptions} />
          </div>
        );
      case 'keyword-rankings':
        return (
          <div className="chart-container">
            <h2 className="text-xl font-bold flex items-center">
              <FaSearch className="mr-2" /> Keyword Rankings
            </h2>
            <Pie data={pieChartData} options={pieChartOptions} />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="seo-metrics-group bg-white shadow-lg rounded-lg p-6">
      <h2 className="text-2xl font-bold flex items-center mb-4">
        <FaSearch className="mr-2" /> SEO Metrics Dashboard
      </h2>
      <div className="navigation mb-4">
        {sections.map((section) => (
          <button
            key={section.id}
            className={`nav-button ${activeSection === section.id ? 'active' : ''}`}
            onClick={() => setActiveSection(section.id)}
          >
            <section.icon className="mr-2" />
            {section.label}
          </button>
        ))}
      </div>
      <div className="content">{renderContent()}</div>
    </div>
  );
};

export default SeoMetricsGroup;
