import React, { useState, useEffect } from 'react';
import { FaTimes, FaTable, FaDatabase, FaFolder, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { fetchTableInfo, fetchDatabaseSize } from '../services/ServerApi';
import './Section3Modal.css';

const Section3Modal = ({ onClose }) => {
  const [tableInfo, setTableInfo] = useState([]);
  const [databaseSize, setDatabaseSize] = useState(null);
  const [expandedTables, setExpandedTables] = useState({});
  const [loading, setLoading] = useState(true);

  const [showTableInfo, setShowTableInfo] = useState(false);
  const [showDatabaseSize, setShowDatabaseSize] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const tableInfoData = await fetchTableInfo();
        const databaseSizeData = await fetchDatabaseSize();



        setTableInfo(tableInfoData);
        setDatabaseSize(databaseSizeData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    getData();
  }, []);

  const toggleTable = (tableName) => {
    setExpandedTables((prev) => ({
      ...prev,
      [tableName]: !prev[tableName],
    }));
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="modal-overlay-section3">
      <div className="modal-container-section3">
        <div className="modal-header-section3">
          <h2>Database Details</h2>
          <button className="close-button-section3" onClick={onClose}>
            <FaTimes />
          </button>
        </div>
        <div className="modal-content-section3">
          <div className="info-section-container-section3">
            <div className="info-section-section3">
              <div className="section-header-section3" onClick={() => setShowTableInfo(!showTableInfo)}>
                <FaTable className="section-icon-section3" />
                <h3>Table Information</h3>
                {showTableInfo ? <FaChevronUp /> : <FaChevronDown />}
              </div>
              {showTableInfo && (
                <div className="section-content-section3">
                  {tableInfo.length ? (
                    tableInfo.map((table, index) => (
                      <div key={index} className="table-item-section3">
                        <div onClick={() => toggleTable(table.tableName)} className="table-header-section3">
                          <FaFolder className="folder-icon-section3" />
                          <strong>{table.tableName}</strong>
                          {expandedTables[table.tableName] ? <FaChevronUp /> : <FaChevronDown />}
                        </div>
                        {expandedTables[table.tableName] && (
                          <div className="column-list-section3">
                            {table.columns.map((column, colIndex) => (
                              <span key={colIndex} className="column-item-section3">{column}</span>
                            ))}
                          </div>
                        )}
                      </div>
                    ))
                  ) : (
                    <div>No tables found.</div>
                  )}
                </div>
              )}
            </div>
            <div className="info-section-section3">
              <div className="section-header-section3" onClick={() => setShowDatabaseSize(!showDatabaseSize)}>
                <FaDatabase className="section-icon-section3" />
                <h3>Database Size</h3>
                {showDatabaseSize ? <FaChevronUp /> : <FaChevronDown />}
              </div>
              {showDatabaseSize && (
                <div className="section-content-section3">
                  {databaseSize ? (
                    <div className="database-size-section3">
                      <FaDatabase className="database-icon-section3" /> {databaseSize.size_in_mb} MB
                    </div>
                  ) : (
                    <div>No size information found.</div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section3Modal;
